import { api } from "../axios/api";

export const create = async (data) => {
  return api.post(`bus`, data);
};

export const fetchAll = async (params) => {
  return api.get(`bus`, {params});
};

export const fetchOne = async (id) => {
  return api.get(`bus/${id || ""}`);
};

export const update = async (id, data) => {
  return api.put(`bus/${id}`, data);
};

