import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  Breadcrumb,
  Button,
  Card,
  Confirm,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  List,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { authState } from "../../atoms/auth";
import Page from "../../components/Page";

import * as ticketsService from "../../services/ticketsService";
import { getHourFromDate } from "../../utils/commonsHelper";
import currency from "currency.js";
import { toast } from "react-toastify";

function BookingDetails() {
  const [ticket, setTicket] = useState();
  const userData = useRecoilValue(authState);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  let { ticketId } = useParams();
  const navigate = useNavigate();

  const fetchTicket = async () => {
    try {
      const response = await ticketsService.fetchOne(ticketId);

      if (response.data && typeof response.data === "object") {
        setTicket(response.data);
      }
    } catch (error) { }
  };

  const validateTicket = async () => {
    try {
      setShowConfirm(false);
      setLoading(true);
      const response = await ticketsService.validateTicket(ticket?.idticket);
      if (response.status !== 200) {
        throw new Error(response.data);
      }
      toast(`${"Ticket validé avec succès"}`, {
        type: "success",
        theme: "colored",
      });
      fetchTicket();
      setShowConfirm(false);
    } catch (error) {
      toast(`${error?.message || "Erreur inattendue pendant la requête!"}`, {
        type: "error",
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ticketId !== undefined) {
      fetchTicket();
    }
    return () => { };
  }, [ticketId]);
  return (
    <Page title="BookingsList">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/company/bookings">
          Réservations
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Détails de la réservation
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>#{ticket?.code}
        </Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <GridRow className="BookingsList">
        
        <Grid>
          <Grid.Column width={12}>
            <Segment>
              {ticket ? (
                <Grid>
                  <Grid.Column width={8}>
                    <List celled relaxed>
                      <List.Item>
                        <List.Icon name="address card" />
                        <List.Content>
                          <List.Header as="a">#Id</List.Header>
                          <List.Description>{ticket?.code}</List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="user" />
                        <List.Content>
                          <List.Header as="a">Client</List.Header>
                          <List.Description>
                            {`${ticket.users_ticket_idusersTousers.last_name} ${ticket.users_ticket_idusersTousers.first_name}`}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="user" />
                        <List.Content>
                          <List.Header as="a">Passager</List.Header>
                          <List.Description>
                            {`${ticket.ticket_passenger.last_name} ${ticket.ticket_passenger.first_name}`}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="map marker" />
                        <List.Content>
                          <List.Header as="a">Trajet</List.Header>
                          <List.Description>
                            {ticket.travels_params.routes.cities_routes_iddepartureTocities.libelle?.toUpperCase()}{" "}
                            -{" "}
                            {ticket.travels_params.routes.cities_routes_idarrivalTocities.libelle?.toUpperCase()}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="bus" />
                        <List.Content>
                          <List.Header as="a">Agence</List.Header>
                          <List.Description>
                            {ticket.agencies.denomination}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </List>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <List celled relaxed>
                      <List.Item>
                        <List.Icon name="calendar" />
                        <List.Content>
                          <List.Header as="a">Heure de départ</List.Header>
                          <List.Description>
                            {`${dayjs(ticket.startDate).format(
                              "DD-MM-YYYY"
                            )} à ${getHourFromDate(ticket.startHour)}`}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="calendar" />
                        <List.Content>
                          <List.Header as="a">Date de réservation</List.Header>
                          <List.Description>
                            {dayjs(ticket.createdAt).format(
                              "DD-MM-YYYY à HH:mm"
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="calendar" />
                        <List.Content>
                          <List.Header as="a">Date de validation</List.Header>
                          <List.Description>
                            {ticket.validated === 1
                              ? dayjs(ticket.validatedAt).format(
                                "DD-MM-YYYY à HH:mm"
                              )
                              : " N/A"}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="check circle" />
                        <List.Content>
                          <List.Header as="a">Statut</List.Header>
                          <List.Description>
                            {ticket.validated === 1
                              ? "Validé"
                              : dayjs().diff(dayjs(ticket.startDate)) >= 0
                                ? "Expiré"
                                : "Actif"}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="money" />
                        <List.Content>
                          <List.Header as="a">Montant</List.Header>
                          <List.Description>
                            {currency(Number(ticket?.price)).format({
                              separator: " ",
                              precision: 0,
                              symbol: "",
                            })}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </List>
                  </Grid.Column>
                </Grid>
              ) : (
                <Message>
                  <Message.Content>
                    Aucune information à propos du ticket
                  </Message.Content>
                </Message>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              <Message>
                <Message.Header>Note!</Message.Header>
                <Message.Content>
                  La validation du ticket se fait contre paiement du titre de
                  voyage!
                </Message.Content>
              </Message>
              {userData?.user?.user_type === "is_agency_user" && (
                <Button
                  fluid
                  positive
                  disabled={dayjs().diff(dayjs(ticket?.startDate)) >= 0 || ticket?.validated === 1}
                  onClick={() => setShowConfirm(true)}
                  loading={loading}
                >
                  Valider
                </Button>
              )}
            </Segment>
          </Grid.Column>
        </Grid>
      </GridRow>
      <Confirm
        open={showConfirm}
        header="Validation du ticket de transport"
        content="Souhaitez-vous valider ce ticket?"
        onCancel={() => setShowConfirm(false)}
        onConfirm={validateTicket}
        cancelButton="NON"
        confirmButton="OUI"
        closeIcon={true}
      />
    </Page>
  );
}

export default BookingDetails;
