import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Icon, Menu } from "semantic-ui-react";
import { authState } from "../../atoms/auth";

function AgencyMenus() {
  const [authValue, setAuthValue] = useRecoilState(authState);

  return (
    <React.Fragment>
      <Menu.Item as={Link} to="dashboard">
        <span>
          <Icon name="dashboard" /> Dashboard
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="bookings">
        <span>
          <Icon name="tags" /> Réservations
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="schedule">
        <span>
          <Icon name="calendar" /> Programmation
        </span>
      </Menu.Item>     
      <Menu.Item as={Link} to="details">
        <span>
          <Icon name="building" /> Agence
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="buses">
        <span>
          <Icon name="bus" /> Bus
        </span>
      </Menu.Item>
     {/*  <Menu.Item as={Link} to="buses">
        <span>
          <Icon name="drivers license" /> Chauffeurs
        </span>
      </Menu.Item> */}
      <Menu.Item as={Link} to="routes">
        <span>
          <Icon name="map marker" /> Routes
        </span>
      </Menu.Item>
     {/*  <Menu.Item as="a">
        <span>
          <Icon name="cogs" /> Paramètres
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="notifications">
        <span>
          <Icon name="bell" /> Notifications
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="messages">
        <span>
          <Icon name="envelope" /> Messages
        </span>
      </Menu.Item> */}
      <Menu.Item as="a">
        <span>
          <Icon name="user" /> Mon profile
        </span>
      </Menu.Item>
    </React.Fragment>
  );
}

export default AgencyMenus;
