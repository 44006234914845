import React, { useEffect, useState } from "react";
import { Button, Form, Header, Input, Modal } from "semantic-ui-react";
import { useFormik } from "formik";
import * as yup from "yup";
import * as citiesService from "../../services/citiesService";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  libelle: yup.string().min(4).max(255).required(),
  active: yup.boolean(),
});

function CityFormModal({
  successCallback = () => {},
  closeCallback = () => {},
  open = false,
  setOpen,
  selectedCityId,
}) {
  const fetchCity = () => {
    citiesService.fetchOne(selectedCityId).then(
      (response) => {
        setFieldValue("libelle", response.data.libelle, false);
        setFieldValue("active", response.data.active === 1, false);
      },
      (reason) => {
        toast(
          `${
            reason?.response?.data ||
            "Une erreur s'est produite lors de la requête."
          }`,
          {
            type: "error",
            theme: "colored",
          }
        );
        setOpen(false);
      }
    );
  };

  const formik = useFormik({
    initialValues: {
      libelle: "",
      active: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const request = selectedCityId
        ? citiesService.update(selectedCityId, values)
        : citiesService.create(values);
      request
        .then(
          (response) => {
            toast(
              `Ville ${selectedCityId ? "Modifiée" : "ajoutée"} avec succès.`,
              {
                type: "success",
                theme: "colored",
              }
            );
            successCallback();
            setOpen(false);
          },
          (reason) => {
            toast(
              `${
                reason?.response?.data ||
                "Une erreur s'est produite lors de la requête."
              }`,
              {
                type: "error",
                theme: "colored",
              }
            );
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    handleReset,
  } = formik;

  useEffect(() => {
    handleReset();
    if (selectedCityId) {
      fetchCity();
    }
    return () => {};
  }, [selectedCityId]);

  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      as={Form}
      size={"tiny"}
    >
      <Modal.Header>{`${
        selectedCityId ? "Edition de la ville" : "Ajouter une ville"
      }`}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Form.Field required error={"libelle" in errors}>
            <label>Ville</label>
            <Input value={values.libelle} onChange={handleChange("libelle")} />
            <small>{errors.libelle}</small>
          </Form.Field>
          <Form.Field>
            <Form.Checkbox
              onChange={(e, data) => setFieldValue("active", data.checked)}
              label="Activer la ville"
              checked={values.active}
            />
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          loading={isSubmitting}
          type="submit"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CityFormModal;
