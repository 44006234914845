import { api } from "../axios/api";

export const create = async (data) => {
  return api.post(`agencies`, data);
};

export const fetchAll = async (data) => {
  return api.get(`agencies`, data);
};

export const fetchOne = async (id) => {
  return api.get(`agencies/show/${id || ""}`);
};

export const update = async (id, data) => {
  return api.put(`agencies/${id}`, data);
};

export const addUser = async (data) => {
  return api.post(`agencies/users`, data);
};
