import React, { useState } from "react";
import {
  Button,
  Form,
  Modal,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as usersService from "../../services/usersService";
import * as agenciesService from "../../services/agenciesService";
import { useEffect } from "react";

const validationSchema = yup.object().shape({
  idusers: yup.number().required(),
  agencyId: yup.string(),
  active: yup.boolean(),
});

function AddAgencyUserFormModal({
  callback = () => {},
  open = false,
  setOpen = () => {},
  agencyId = null,
}) {
  const [usersOptions, setUsersOptions] = useState([]);
  const formik = useFormik({
    initialValues: {
      idusers: "",
      agencyId: "",
      is_admin: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      agenciesService
        .addUser(values)
        .then(
          (response) => {
            toast(`Utilisateur ajouté avec succès.`, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;

  const fetchUsers = async () => {
    try {
      const response = await usersService.fetchAll("user_type=COMPANY_USER");
      if (response.status === 200 && Array.isArray(response.data)) {
        let options = [
          {
            key: "DEFAULT",
            text: "Choisir...",
            value: "",
          },
        ];
        for (const user of response.data) {
          options.push({
            key: `${user.idusers}`,
            text: `${user.first_name} ${user.last_name} (${user.email})`,
            value: user.idusers,
          });
        }
        setUsersOptions(options);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchUsers();
    setFieldValue("agencyId", agencyId, true);
    return () => {};
  }, [agencyId]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>Ajouter un utilisateur</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {errors.agencyId && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.agencyId}
            </div>
          )}
          <Form.Field error={"idusers" in errors}>
            <label>Utilisateur</label>
            <Form.Select
              scrolling
              search
              fluid
              onChange={(e, data) => setFieldValue("idusers", data.value)}
              options={usersOptions}
              placeholder="Choisr..."
            />
            <small className="field-error">{errors.idusers}</small>
          </Form.Field>

          <Form.Field error={"is_admin" in errors}>
            <Form.Checkbox
              onChange={(e, data) => setFieldValue("is_admin", data.checked)}
              label="Ajouter comme administrateur."
              checked={values.is_admin}
            />
            <small className="field-error">{errors.is_admin}</small>
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default AddAgencyUserFormModal;
