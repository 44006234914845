import { api } from "../axios/api";

export const create = async (data) => {
  return api.post("schedule", data);
};
export const update = async (id, data) => {
  return api.put(`schedule/${id}`, data);
};
export const findAll = async (params) => {
  return api.get("schedule",{params});
};
export const findOne = async (id) => {
  return api.get(`schedule/${id}`);
};
