import { api } from "../axios/api";

export const create = async (data) => {
  return api.post(`cities`, data);
};

export const fetchAll = async (params) => {
  return api.get(`cities`, { params });
};

export const fetchOne = async (id) => {
  return api.get(`cities/${id}`);
};

export const update = async (id, data) => {
  return api.put(`cities/${id}`, data);
};
