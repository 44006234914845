import { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  Pagination,
  Table,
} from "semantic-ui-react";
import Page from "../../components/Page";
import { useNavigate } from "react-router-dom";
import * as scheduleService from "../../services/scheduleService";
import dayjs from "dayjs";
import { DAYS_OF_WEEK } from "../../constants";
import { getHourFromDate, is_admin } from "../../utils/commonsHelper";
import { useRecoilState } from "recoil";
import { authState } from "../../atoms/auth";
import currency from "currency.js";

function ScheduleList() {
  const [authValue, setAuthValue] = useRecoilState(authState);
  const [travels_params, setTravels_Params] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalParams, setTotalParams] = useState(0);
  const navigate = useNavigate();

  const fetchTravelsPrams = () => {
    scheduleService
      .findAll({
        page: currentPage,
        limit: 10,
        search: searchTerm,
      })
      .then(
        (response) => {
          setTravels_Params(response.data.data);
          setTotalPages(response.data.totalPages);
          setTotalParams(response.data.totalParams);
        },
        (reason) => {}
      )
      .catch((error) => {});
  };

  useEffect(() => {
    fetchTravelsPrams();
    return () => {};
  }, [searchTerm, currentPage]);

  return (
    <Page title="ScheduleList">
      <Breadcrumb>
        <Breadcrumb.Section href="/dashboard">Dashboard</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Programmations</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <GridRow className="ScheduleList">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des Programmations [{totalParams || 0}]</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                name="search"
              />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => navigate("add")}
            />
          </Grid.Column>
        </Grid>
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                {is_admin() && <Table.HeaderCell>Compagnie</Table.HeaderCell>}
                <Table.HeaderCell>Agence</Table.HeaderCell>
                <Table.HeaderCell>Début</Table.HeaderCell>
                <Table.HeaderCell>Fin</Table.HeaderCell>
                <Table.HeaderCell>Heure de départ</Table.HeaderCell>
                <Table.HeaderCell>Trajet</Table.HeaderCell>
                <Table.HeaderCell>Prix</Table.HeaderCell>
                <Table.HeaderCell>Jours</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {travels_params?.map((param) => (
                <Table.Row key={param?.idtravels_params?.toString()}>
                  {is_admin() && (
                    <Table.Cell singleLine>
                      {param.agencies?.compagnies.denomination}
                    </Table.Cell>
                  )}
                  <Table.Cell singleLine>
                    {param.agencies?.denomination}
                  </Table.Cell>
                  <Table.Cell>
                    {dayjs(param.startDate).format("DD-MM-YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    {dayjs(param.endDate).format("DD-MM-YYYY")}
                  </Table.Cell>
                  <Table.Cell>{getHourFromDate(param.startHour)}</Table.Cell>
                  <Table.Cell singleLine>
                    {param.routes.cities_routes_iddepartureTocities.libelle} -{" "}
                    {param.routes.cities_routes_idarrivalTocities.libelle}
                  </Table.Cell>
                  <Table.Cell singleLine>
                    {currency(Number(param.price), {
                      separator: " ",
                      precision: 0,
                      symbol: "",
                    }).format()}
                  </Table.Cell>
                  <Table.Cell>
                    <Label.Group>
                      {param?.repeatDays?.map((d) => (
                        <Label key={d?.toString()}>
                          {DAYS_OF_WEEK.at(d).substring(0, 3)}
                        </Label>
                      ))}
                    </Label.Group>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown pointing="right" icon="ellipsis vertical">
                      <Dropdown.Menu>
                        {/* <Dropdown.Item
                          icon="eye"
                          content="Détails"
                          onClick={() =>
                            navigate(
                              `/agency/schedule/details/${param.idtravels_params}`
                            )
                          }
                        />*/}
                        <Dropdown.Item
                          icon="edit"
                          content="Editer"
                          onClick={() =>
                            navigate(
                              `/agency/schedule/edit/${param.idtravels_params}`
                            )
                          }
                        />
                        <Dropdown.Item icon="trash" content="Supprimer" />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="9">
                  <Pagination
                    style={{ float: "right" }}
                    defaultActivePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(e, { activePage }) =>
                      setCurrentPage(activePage)
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      </GridRow>
    </Page>
  );
}

export default ScheduleList;
