import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Container, Grid, Header, Segment } from "semantic-ui-react";

ChartJS.register(
  CategoryScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin"],
  datasets: [
    {
      label: "Compagnie A",
      backgroundColor: "#2185d0",
      borderColor: "#2185d0",
      borderWidth: 1,
      hoverBackgroundColor: "#0a68ad",
      hoverBorderColor: "#0a68ad",
      data: [65, 59, 80, 81, 56, 55],
    },
    {
      label: "Compagnie B",
      backgroundColor: "#21ba45",
      borderColor: "#21ba45",
      borderWidth: 1,
      hoverBackgroundColor: "#16ab39",
      hoverBorderColor: "#16ab39",
      data: [28, 48, 40, 19, 86, 27],
    },
    {
      label: "Compagnie C",
      backgroundColor: "#f2711c",
      borderColor: "#f2711c",
      borderWidth: 1,
      hoverBackgroundColor: "#db6e18",
      hoverBorderColor: "#db6e18",
      data: [45, 32, 67, 23, 44, 88],
    },
  ],
};

const options = {
  maintainAspectRatio: false ,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: (value) => `FCFA ${value.toLocaleString()}`,
        },
      },
    ],
  },
  legend: {
    labels: {
      usePointStyle: true,
    },
  },
};

const ComparativePerformanceChart = () => {
  return (
    <Segment style={{ height: "400px", width: "100%",paddingBottom: "50px" }}>
      <Header as="h3">Revenues</Header>
      <Bar data={data} options={options} />
    </Segment>
  );
};

export default ComparativePerformanceChart;
