import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Pagination,
  Table,
} from "semantic-ui-react";
import { authState } from "../../../atoms/auth";
import RouteFormModal from "../../../components/FormModals/RouteFormModal";
import * as routesService from "../../../services/routesService";

function TrajetsPaneContent() {
  const [open, setOpen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRoutes, setTotalRoutes] = useState();
  const [selectedRouteId, setSelectedRouteId] = useState();
  const userData = useRecoilValue(authState);

  const fetchRoutes = async () => {
    try {
      const response = await routesService.fetchAll({
        page: currentPage,
        limit: 10,
        search: searchTerm,
      });
      if (response?.status === 200 && Array.isArray(response.data.data)) {
        setRoutes(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalRoutes(response.data.totalRoutes);
      }
    } catch (error) {}
  };

  const handleOpenEdit = (cityId) => {
    setSelectedRouteId(cityId);
    setOpen(true);
  };

  useEffect(() => {
    fetchRoutes();
    return () => {};
  }, [currentPage, searchTerm]);

  useEffect(() => {
    if (open === false) {
      setSelectedRouteId(null);
    }
    return () => {};
  }, [open]);
  return (
    <React.Fragment>
      <GridRow className="Routes">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          ></Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                name="search"
              />
            </Input>
            {userData?.user?.user_type === "is_company_user" && (
              <Button
                style={{ marginLeft: "10px" }}
                circular
                icon={<Icon name="add" />}
                onClick={() => setOpen(true)}
              />
            )}
          </Grid.Column>
        </Grid>
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Départ</Table.HeaderCell>
                <Table.HeaderCell>Arrivée</Table.HeaderCell>
                <Table.HeaderCell>Durée</Table.HeaderCell>
                <Table.HeaderCell>Actif</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {routes?.map((route, index) => (
                <Table.Row key={`${route.idroutes}`}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>
                    {route.cities_routes_iddepartureTocities.libelle}
                  </Table.Cell>
                  <Table.Cell>
                    {route.cities_routes_idarrivalTocities.libelle}
                  </Table.Cell>
                  <Table.Cell>{`${route.hours} heure(s) et ${route.minutes}`}</Table.Cell>
                  <Table.Cell>{route.active === 1 ? "OUI" : "NON"}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown pointing="right" icon="ellipsis vertical">
                      <Dropdown.Menu>
                        <Dropdown.Item icon="eye" content="Détails" />
                        {userData?.user?.user_type === "is_company_user" && (
                          <>
                            <Dropdown.Item
                              icon="edit"
                              content="Editer"
                              onClick={() => handleOpenEdit(route.idroutes)}
                            />
                            <Dropdown.Item icon="trash" content="Supprimer" />
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="6">
                  <Pagination
                    style={{ float: "right" }}
                    defaultActivePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(e, { activePage }) =>
                      setCurrentPage(activePage)
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      </GridRow>
      <RouteFormModal
        open={open}
        selectedRouteId={selectedRouteId}
        setOpen={setOpen}
        successCallback={fetchRoutes}
      />
    </React.Fragment>
  );
}

export default TrajetsPaneContent;
