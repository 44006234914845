import { useState, useEffect } from "react";
import {
  Grid,
  GridRow,
  Header,
  Segment,
  Tab,
} from "semantic-ui-react";
import Page from "../../../components/Page";
import { useNavigate } from "react-router-dom";
import * as scheduleService from "../../../services/scheduleService";
import { useRecoilState } from "recoil";
import { authState } from "../../../atoms/auth";
import ConfigurationPane from "./ConfigurationPane";

function ScheduleDetailsPage() {
  const [authValue, setAuthValue] = useRecoilState(authState);
  const [travels_params, setTravels_Params] = useState();
  const navigate = useNavigate();

  const fetchTravelsPrams = () => {
    scheduleService
      .findAll()
      .then(
        (response) => {
          setTravels_Params(response.data);

        },
        (reason) => {

        }
      )
      .catch((error) => {

      });
  };

  useEffect(() => {
    fetchTravelsPrams();

    return () => { };
  }, []);

  const panes = [
    {
      menuItem: "Configuration",
      render: () => <Tab.Pane>{<ConfigurationPane />}</Tab.Pane>,
    },
  ];

  return (
    <Page title="ScheduleDetailsPage">
      <GridRow className="ScheduleDetailsPage">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Details de la programmation</Header>
          </Grid.Column>
        </Grid>
        <GridRow style={{ marginTop: "16px" }}>
          <Tab as={Segment} panes={panes} />
        </GridRow>
      </GridRow>
    </Page>
  );
}

export default ScheduleDetailsPage;
