import { api } from "../axios/api";

export const create = async (data) => {
  return api.post(`compagnies`, data);
};

export const fetchAll = async (params) => {
  return api.get(`compagnies`, { params });
};

export const fetchOne = async (id) => {
  return api.get(`compagnies/show/${id || ""}`);
};

export const update = async (id, data) => {
  return api.put(`compagnies/${id}`, data);
};

export const addUser = async (data) => {
  return api.post(`compagnies/users`, data);
};
