import React, { useEffect, useState } from "react";
import { Button, Form, Header, Input, Modal } from "semantic-ui-react";
import { useFormik } from "formik";
import * as yup from "yup";
import * as agenciesService from "../../services/agenciesService";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  denomination: yup.string().min(4).max(255).required(),
  phone: yup.string().min(8).max(16).required(),
  email: yup.string().email().max(255).required(),
  address: yup.string().max(255).required(),
  active: yup.boolean(),
});

function AgencyFormModal({
  successCallback = () => {},
  closeCallback = () => {},
  open = false,
  setOpen,
  selectedAgencyId,
}) {
  const fetchCompany = () => {
    agenciesService.fetchOne(selectedAgencyId).then(
      (response) => {
        setFieldValue("denomination", response.data.denomination, false);
        setFieldValue("phone", response.data.phone, false);
        setFieldValue("email", response.data.email, false);
        setFieldValue("address", response.data.address, false);
        setFieldValue("active", response.data.active === 1, false);
      },
      (reason) => {
        toast(
          `${
            reason?.response?.data ||
            "Une erreur s'est produite lors de la requête."
          }`,
          {
            type: "error",
            theme: "colored",
          }
        );
        setOpen(false);
      }
    );
  };
  const formik = useFormik({
    initialValues: {
      denomination: "",
      phone: "",
      email: "",
      active: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const request = selectedAgencyId
        ? agenciesService.update(selectedAgencyId, values)
        : agenciesService.create(values);
      request
        .then(
          (response) => {
            toast(
              `Agence ${
                selectedAgencyId ? "Modifiée" : "ajoutée"
              } avec succès.`,
              {
                type: "success",
                theme: "colored",
              }
            );
            successCallback();
            handleReset();
            setOpen(false);
          },
          (reason) => {
            toast(
              `${
                reason?.response?.data ||
                "Une erreur s'est produite lors de la requête."
              }`,
              {
                type: "error",
                theme: "colored",
              }
            );
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    handleReset,
  } = formik;

  useEffect(() => {
    handleReset();
    if (selectedAgencyId) {
      fetchCompany();
    }
    return () => {};
  }, [selectedAgencyId]);

  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      as={Form}
      size={"tiny"}
    >
      <Modal.Header>{`${
        selectedAgencyId ? "Edition d'une agence" : "Ajouter une agence"
      }`}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Form.Field required error={"denomination" in errors}>
            <label>Dénomination</label>
            <Input
              value={values.denomination}
              onChange={handleChange("denomination")}
            />
            <small>{errors.denomination}</small>
          </Form.Field>
          <Form.Field required error={"address" in errors}>
            <label>Adresse</label>
            <Input value={values.address} onChange={handleChange("address")} />
            <small>{errors.address}</small>
          </Form.Field>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"email" in errors}>
              <label>Email</label>
              <Input value={values.email} onChange={handleChange("email")} />
              <small>{errors.email}</small>
            </Form.Field>
            <Form.Field required error={"phone" in errors}>
              <label>Téléphone</label>
              <Input value={values.phone} onChange={handleChange("phone")} />
              <small>{errors.phone}</small>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <Form.Checkbox
              onChange={(e, data) => setFieldValue("active", data.checked)}
              label="Activer l'agence"
              checked={values.active}
            />
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          loading={isSubmitting}
          type="submit"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default AgencyFormModal;
