import { Card, Icon, Rating } from "semantic-ui-react";

const testimonials = [
  {
    name: "Aminata Doumbia",
    rating: 4,
    comment:
      "Go-tour m'a permis de voyager confortablement à travers tout le pays.",
  },
  {
    name: "Amadou Traoré",
    rating: 5,
    comment:
      "J'ai été agréablement surpris par la simplicité et l'efficacité de l'application Go-tour.",
  },
  {
    name: "Fatoumata Koné",
    rating: 3,
    comment:
      "L'application a encore du chemin à faire, mais elle est prometteuse.",
  },
  {
    name: "Boubacar Diarra",
    rating: 4,
    comment: "Je recommande vivement Go-tour pour les voyages en bus au Mali.",
  },
];

export const Testimonials = () => {
  return (
    <Card.Group itemsPerRow={4} stackable doubling>
      {testimonials.map((testimonial, index) => (
        <Card key={index}>
          <Card.Content header={testimonial.name} />
          <Card.Content description={testimonial.comment?.trim()} />
          <Card.Content extra>
            <Rating icon="star" defaultRating={3} maxRating={4} />
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  );
};
