import { RecoilRoot, useRecoilState } from "recoil";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginPage from "./pages/auth/LoginPage";

import DashboardLayout from "./components/DashboardLayout";
import Dashboard from "./pages/dashboard/Dashboard";
import Compagnies from "./pages/comagnies/CompagniesList";
import "react-toastify/dist/ReactToastify.min.css";
import { authState } from "./atoms/auth";
import ProtectedRoute from "./components/ProtectedRoute";
import RoutesPage from "./pages/rooutes/RoutesPage";
import UsersPage from "./pages/users/UsersPage";
import NotificationsPage from "./pages/nootifications/NotificationsPage";
import MessagesPage from "./pages/messages/MessagesPage";
import CompagniesDetails from "./pages/comagnies/CompagniesDetails";
import AgenceDetails from "./pages/agences/AgenceDetails";
import BookingsList from "./pages/bookings/BookingsList";
import BusListPage from "./pages/bus/BusListPage";
import ScheduleList from "./pages/schedule/ScheduleList";
import AddSchedulePage from "./pages/schedule/AddSchedulePage";
import EditSchedulePage from "./pages/schedule/EditSchedulePage";
import ScheduleDetailsPage from "./pages/schedule/details/ScheduleDetailsPage";
import BookingDetails from "./pages/bookings/BookingDetails";
import HomePage from "./pages/web/HomePage";
import { useEffect } from "react";
import {
  getUserFromStorage,
  removeUserFromStorage,
} from "./services/authService";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import Tickets from "./pages/configuration/Tickets";

function App() {
  const [authValue, setAuthValue] = useRecoilState(authState);
  useEffect(() => {
    const initAuth = () => {
      try {
        let userData = getUserFromStorage();
        const decodedToken = jwt_decode(userData?.token);
        const today = dayjs();
        const tokenExpireAt = dayjs(decodedToken?.exp * 1000);
        if (today.diff(tokenExpireAt, "day") >= 0) {
          throw new Error("Your token as expired");
        }
        setAuthValue(userData);
       
      } catch (error) {
        removeUserFromStorage();
        setAuthValue(null);
      }
    };
    initAuth();
    return () => {};
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="connexion" element={<LoginPage />} />
          <Route
            path={"admin/*"}
            element={
              <ProtectedRoute exept={["ADMIN"]}>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="compagnies/*">
              <Route index element={<Compagnies />} />
              <Route path=":id" element={<CompagniesDetails />} />
            </Route>
            <Route path="routes/*">
              <Route index element={<RoutesPage />} />
            </Route>
            <Route path="users/*">
              <Route index element={<UsersPage />} />
            </Route>
            <Route path="notifications/*">
              <Route index element={<NotificationsPage />} />
            </Route>
            <Route path="messages/*">
              <Route index element={<MessagesPage />} />
            </Route>
            <Route path="configuration/*">
              <Route path="ticket-de-transport" element={<Tickets />} />
            </Route>
            <Route path="agencies/*">
              <Route path=":id" element={<AgenceDetails />} />
            </Route>
          </Route>
          <Route
            path={"company/*"}
            element={
              <ProtectedRoute exept={["CUSTOMER"]}>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="compagnie/*">
              <Route index element={<CompagniesDetails />} />
            </Route>
            <Route path="buses/*">
              <Route index element={<BusListPage />} />
            </Route>
            <Route path="agencies/*">
              <Route path=":id" element={<AgenceDetails />} />
            </Route>
            <Route path="routes/*">
              <Route index element={<RoutesPage />} />
            </Route>
            <Route path="users/*">
              <Route index element={<UsersPage />} />
            </Route>
            <Route path="notifications/*">
              <Route index element={<NotificationsPage />} />
            </Route>
            <Route path="messages/*">
              <Route index element={<MessagesPage />} />
            </Route>
            <Route path="bookings/*">
              <Route index element={<BookingsList />} />
              <Route path=":ticketId" element={<BookingDetails />} />
            </Route>
            <Route path="schedule/*">
              <Route index element={<ScheduleList />} />
              <Route path="add" element={<AddSchedulePage />} />
            </Route>
          </Route>
          <Route
            path={"agency/*"}
            element={
              <ProtectedRoute exept={["CUSTOMER"]}>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="details" element={<AgenceDetails />} />
            <Route path="compagnie/*">
              <Route index element={<CompagniesDetails />} />
            </Route>
            <Route path="buses/*">
              <Route index element={<BusListPage />} />
            </Route>

            <Route path="routes/*">
              <Route index element={<RoutesPage />} />
            </Route>
            <Route path="users/*">
              <Route index element={<UsersPage />} />
            </Route>
            <Route path="notifications/*">
              <Route index element={<NotificationsPage />} />
            </Route>
            <Route path="messages/*">
              <Route index element={<MessagesPage />} />
            </Route>
            <Route path="bookings/*">
              <Route index element={<BookingsList />} />
              <Route path=":ticketId" element={<BookingDetails />} />
            </Route>
            <Route path="schedule/*">
              <Route index element={<ScheduleList />} />
              <Route path="add" element={<AddSchedulePage />} />
              <Route path="edit/:paramsId" element={<EditSchedulePage />} />
              <Route
                path="details/:paramsId"
                element={<ScheduleDetailsPage />}
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
