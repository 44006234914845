import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Container, Grid, Header, Segment } from "semantic-ui-react";
import { getRandomColor } from "../../../utils/commonsHelper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
  datasets: [
    {
      label: "Taux d'occupation",
      data: [70, 60, 80, 90, 75, 85, 95],
      backgroundColor: getRandomColor(),
      borderWidth: 1,
    },
  ],
};

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          suggestedMax: 100,
        },
      },
    ],
  },
  legend: {
    labels: {
      usePointStyle: true,
    },
  },
};

const OccupancyChart  = () => {
  return (
    <Segment style={{ height: "400px", width: "100%", paddingBottom: "50px" }}>
      <Header as="h3">Taux d'occupation</Header>
      <Bar data={data} options={options} height={300} />
    </Segment>
  );
};

export default OccupancyChart ;
