import React, { useEffect, useState } from "react";
import { Button, Form, Header, Icon, Input, Modal } from "semantic-ui-react";
import { useFormik } from "formik";
import * as yup from "yup";
import * as usersService from "../../services/usersService";
import { toast } from "react-toastify";
import { GENDER_LIST, USER_GROUPS } from "../../constants";

const validationSchema = yup.object().shape({
  first_name: yup.string().min(4).max(45).required(),
  last_name: yup.string().min(4).max(45).required(),
  gender: yup
    .string()
    .oneOf([GENDER_LIST.AUTRE, GENDER_LIST.FEMME, GENDER_LIST.HOMME])
    .required(),
  user_group: yup
    .string()
    .oneOf([USER_GROUPS.CUSTOMER, USER_GROUPS.ADMIN, USER_GROUPS.PARTNER])
    .required(),
  email: yup.string().email().max(255).required(),
  phone: yup.string().min(8).max(12).required(),
  password: yup.string().min(8).max(64).notRequired(),
  active: yup.boolean(),
});

function UserFormModal({
  successCallback = () => {},
  closeCallback = () => {},
  open = false,
  setOpen,
  selectedUserId,
}) {
  const fetchCompany = () => {
    usersService.fetchOne(selectedUserId).then(
      (response) => {
        setFieldValue("first_name", response.data.first_name, false);
        setFieldValue("last_name", response.data.last_name, false);
        setFieldValue("phone", response.data.phone, false);
        setFieldValue("email", response.data.email, false);
        setFieldValue("gender", response.data.gender, false);
        setFieldValue("user_group", response.data.user_group, false);
        setFieldValue("active", response.data.active === 1, false);
      },
      (reason) => {
        toast(
          `${
            reason?.response?.data ||
            "Une erreur s'est produite lors de la requête."
          }`,
          {
            type: "error",
            theme: "colored",
          }
        );
        setOpen(false);
      }
    );
  };
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      gender: "",
      user_group: "",
      email: "",
      phone: "",
      active: true,
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const request = selectedUserId
        ? usersService.update(selectedUserId, values)
        : usersService.create(values);
      request
        .then(
          (response) => {
            toast(
              `Utilisateur ${
                selectedUserId ? "Modifié" : "ajouté"
              } avec succès.`,
              {
                type: "success",
                theme: "colored",
              }
            );
            successCallback();
            handleReset();
            setOpen(false);
          },
          (reason) => {
            toast(
              `${
                reason?.response?.data ||
                "Une erreur s'est produite lors de la requête."
              }`,
              {
                type: "error",
                theme: "colored",
              }
            );
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    handleReset,
  } = formik;

  useEffect(() => {
    handleReset();
    if (selectedUserId) {
      fetchCompany();
      validationSchema.shape({
        password: yup.string().notRequired()
      })
    }
    return () => {};
  }, [selectedUserId]);

  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      as={Form}
      size={"tiny"}
    >
      <Modal.Header>{`${
        selectedUserId ? "Edition de l'utilisateur" : "Ajouter un utilisateur"
      }`}</Modal.Header>
      <Modal.Content>
        <Modal.Description scrolling>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"gender" in errors}>
              <label>Genre</label>
              <Form.Select
                options={[
                  {
                    key: GENDER_LIST.AUTRE,
                    text: "Autre",
                    value: GENDER_LIST.AUTRE,
                  },
                  {
                    key: GENDER_LIST.HOST,
                    text: "Homme",
                    value: GENDER_LIST.HOMME,
                  },
                  {
                    key: GENDER_LIST.FEMME,
                    text: "Femme",
                    value: GENDER_LIST.FEMME,
                  },
                ]}
                value={values.gender}
                onChange={(e, data) => setFieldValue("gender", data.value)}
              />
              <small>{errors.gender}</small>
            </Form.Field>
            <Form.Field required error={"user_group" in errors}>
              <label>Type de compte</label>
              <Form.Select
                options={[
                  {
                    key: USER_GROUPS.ADMIN,
                    text: "Administrateur",
                    value: USER_GROUPS.ADMIN,
                  },
                  {
                    key: USER_GROUPS.CUSTOMER,
                    text: "Client",
                    value: USER_GROUPS.CUSTOMER,
                  },
                  {
                    key: USER_GROUPS.PARTNER,
                    text: "Parténaire (Agence de voyage)",
                    value: USER_GROUPS.PARTNER,
                  },
                ]}
                value={values.user_group}
                onChange={(e, data) => setFieldValue("user_group", data.value)}
              />
              <small>{errors.user_group}</small>
            </Form.Field>
          </Form.Group>

          <Form.Group widths={"equal"}>
            <Form.Field required error={"last_name" in errors}>
              <label>Nom</label>
              <Input
                value={values.last_name}
                onChange={handleChange("last_name")}
              />
              <small>{errors.last_name}</small>
            </Form.Field>
            <Form.Field required error={"first_name" in errors}>
              <label>Prénom</label>
              <Input
                value={values.first_name}
                onChange={handleChange("first_name")}
              />
              <small>{errors.first_name}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"email" in errors}>
              <label>Email</label>
              <Input value={values.email} onChange={handleChange("email")} />
              <small>{errors.email}</small>
            </Form.Field>
            <Form.Field required error={"phone" in errors}>
              <label>Téléphone</label>
              <Input value={values.phone} onChange={handleChange("phone")} />
              <small>{errors.phone}</small>
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field required error={"password" in errors}>
              <label>Mot de passe</label>
              <Input
                value={values.password}
                icon={
                  <Icon
                    name="magic"
                    link
                    onClick={() => {
                      const password = Math.random().toString(36).slice(-8);
                      setFieldValue("password", password);
                    }}
                  />
                }
              />
              <small>{errors.password}</small>
            </Form.Field>
            <Form.Field inline>
              <Form.Checkbox
                onChange={(e, data) => setFieldValue("active", data.checked)}
                label="Rendre l'utilisateur actif"
                checked={values.active}
              />
            </Form.Field>
          </Form.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          loading={isSubmitting}
          type="submit"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default UserFormModal;
