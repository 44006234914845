import dayjs from 'dayjs';
import React, { useState } from 'react'
import { Card, Dropdown, Icon } from 'semantic-ui-react';
import { getHourFromDate } from '../utils/commonsHelper';
import { useNavigate } from 'react-router-dom';


const ReservationCard = ({ reservation }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const navigate = useNavigate();
    const navigateToDetails = () => {
        navigate(`${reservation.idticket}`);
    };


    const handleDropdownClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDropdownClose = () => {
        setIsDropdownOpen(false);
    };



    const dropdownOptions = [
        { key: 'details', text: 'Détails', icon: 'eye', onClick: navigateToDetails },
        { key: 'delete', text: 'Supprimer', icon: 'trash', onClick: handleDropdownClose },
    ];

    return (
        <Card fluid>
            <Card.Content>
                <Card.Header>ID : {reservation.code}</Card.Header>
                <Card.Meta>
                    Auteur : {`${reservation.users_ticket_idusersTousers.last_name} ${reservation.users_ticket_idusersTousers.first_name}`} | Passager : {`${reservation.ticket_passenger.last_name} ${reservation.ticket_passenger.first_name}`} | Agence : {reservation.agencies.denomination}
                </Card.Meta>
                <Card.Description>
                    Trajet : {
                        reservation.travels_params.routes
                            .cities_routes_iddepartureTocities.libelle
                    }{" "}
                    -{" "}
                    {
                        reservation.travels_params.routes
                            .cities_routes_idarrivalTocities.libelle
                    } | Date de départ : {reservation.dateDepart} | Date de réservation :{' '}
                    {`${dayjs(reservation.startDate).format(
                        "DD-MM-YYYY"
                    )} à ${getHourFromDate(reservation.startHour)}`}
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <div className="float-right">
                    <Icon style={{ cursor: "pointer" }} name="ellipsis vertical" onClick={handleDropdownClick} />
                    <Dropdown
                        trigger={null}
                        open={isDropdownOpen}
                        onClose={handleDropdownClose}
                        direction="left"
                        icon={null}
                    >
                        <Dropdown.Menu>
                            {dropdownOptions.map((option) => (
                                <Dropdown.Item
                                    key={option.key}
                                    text={option.text}
                                    icon={option.icon}
                                    onClick={option.onClick}
                                />
                            ))}

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <span style={{ marginLeft: '10px' }}>Statut : {reservation.validated === 1
                    ? "Validé"
                    : dayjs().diff(dayjs(reservation.startDate)) >= 0
                        ? "Expiré"
                        : "Actif"}</span>
            </Card.Content>
        </Card>
    );
};

export default ReservationCard