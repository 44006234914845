import React from "react";
import {
  Breadcrumb,
  Divider,
  Grid,
  GridRow,
  Header,
  Input,
} from "semantic-ui-react";
import CompanyDashboard from "../../components/CompanyDashboard";
import Page from "../../components/Page";

function Dashboard() {
  return (
    <Page title="Dashboard">
      <Breadcrumb>
        <Breadcrumb.Section link>Home</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Dashboard</Breadcrumb.Section>
      </Breadcrumb>
        
      <Divider/>
      <GridRow className="DashBoard">
        <CompanyDashboard />
      </GridRow>
    </Page>
  );
}

export default Dashboard;
