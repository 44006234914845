import { Fragment, useState } from "react";
import {
  Button,
  Grid,
  GridRow,
  Image,
  Form,
  Input,
  Header,
  Icon,
  GridColumn,
  Menu,
  Dropdown,
  Container,
  List,
  Tab,
  Statistic,
  Segment,
  Divider,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import Logo from "../../assets/images/logo_type_login_redim.png";
import "../../styles/loginPage.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Testimonials } from "../../components/home/Testimonials";

const HomePage = () => {
  const [menuShow, setMenuShow] = useState(false);
  return (
    <Fragment>
      <Helmet>
        <title>Go-tour | Voyager n'a jamais été si simple</title>
      </Helmet>
      <GridRow className="HomePage">
        <Menu fixed="top" size="small" borderless>
          <Menu.Item to="/" className="brand" as={Link}>
            <Image avatar src={Logo} />
          </Menu.Item>
          <Menu.Menu position="right" className="desk">
            <Menu.Item name="messages" as={Link} content="Description" />
            <Menu.Item name="messages" as={Link} content="Fonctionnalités" />
            <Menu.Item name="messages" as={Link} content="Ecrans" />
            <Dropdown item text="Extra" pointing>
              <Dropdown.Menu>
                <Dropdown.Item>Articles details</Dropdown.Item>
                <Dropdown.Item>Termes et conditions</Dropdown.Item>
                <Dropdown.Item>Privacy Policy</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Menu.Item>
              <Button as={Link} inverted to="connexion" primary>
                Se connecter
              </Button>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu
            position="right"
            style={{ display: "none" }}
            className="mob"
          >
            <Menu.Item>
              <Button icon onClick={() => setMenuShow(!menuShow)}>
                <Icon name="bars" />
              </Button>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <GridColumn width={16} className="hero">
          <Container style={{ textAlign: "center" }}>
            <Header style={{ color: "white" }}>
              Les voyages sans soucis avec Go-tour
            </Header>
            <p>
              Nous vous offrons une expérience de voyage sans soucis, de la
              réservation jusqu'à votre retour.
            </p>
            <Button inverted color="teal">
              <Icon name="google play" /> Télécharger
            </Button>
          </Container>
        </GridColumn>
        <GridRow className="description-app">
          <Container>
            <Grid stackable columns={2}>
              <Grid.Column>
                <Image
                  src={require("../../assets/images/description-1-app.png")}
                />
              </Grid.Column>
              <Grid.Column>
                <Header> Voyagez en toute simplicité avec Go-tour</Header>
                <p>
                  Go-tour est une application de réservation de billets de
                  transport pour faciliter vos déplacements. Avec notre
                  application, vous pouvez acheter des billets de bus, de train
                  et de ferry en un seul endroit. Nous avons simplifié le
                  processus de réservation, ce qui vous permet de réserver votre
                  billet en quelques minutes seulement.
                </p>

                <p>
                  Notre application vous permet de planifier vos voyages en
                  toute simplicité. Vous pouvez choisir votre trajet,
                  sélectionner les dates et les horaires qui vous conviennent,
                  et réserver votre billet en quelques clics. Vous pouvez
                  également consulter les horaires des bus, trains et ferries en
                  temps réel, ce qui vous permet de rester informé(e) en tout
                  temps.
                </p>
                <List>
                  <List.Item>
                    <List.Icon name="exchange" />
                    <List.Content>
                      Réservez vos billets de transport en quelques minutes
                      seulement
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="calendar" />
                    <List.Content>
                      Planifiez vos voyages en toute simplicité, en choisissant
                      vos dates et horaires de trajet
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="bell" />
                    <List.Content>
                      Restez informé(e) des horaires de bus, de train et de
                      ferry en temps réel
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </Container>
          <div className="des-2" style={{ background: "#e8eff6" }}>
            <Container>
              <Grid stackable columns={2}>
                <Grid.Column>
                  <Tab
                    menu={{ secondary: true, stackable: true }}
                    className="tab-menu"
                    panes={[
                      {
                        menuItem: "Réserver facilement",
                        render: () => (
                          <Tab.Pane attached={false}>
                            <p>
                              Go-tour vous permet de réserver vos billets de
                              transport en quelques clics seulement. Grâce à
                              notre interface intuitive et facile à utiliser,
                              vous pouvez choisir votre destination, votre date
                              de départ et de retour, et le nombre de passagers.
                              Notre application vous proposera ensuite une
                              sélection de billets disponibles, que vous pourrez
                              réserver et payer en toute sécurité.
                            </p>
                          </Tab.Pane>
                        ),
                      },
                      {
                        menuItem: "Suivre en temps réel",
                        render: () => (
                          <Tab.Pane attached={false}>
                            <p>
                              Avec Go-tour, vous pouvez suivre votre trajet en
                              temps réel grâce à notre système de
                              géolocalisation. Vous serez informé de l'heure de
                              départ et d'arrivée prévue, des éventuels retards
                              ou changements de parcours, et pourrez même
                              visualiser la progression du trajet sur une carte
                              interactive. Vous pourrez ainsi voyager en toute
                              sérénité, sans stress ni surprise.
                            </p>
                          </Tab.Pane>
                        ),
                      },
                      {
                        menuItem: "Expérience de voyage unique",
                        render: () => (
                          <Tab.Pane attached={false}>
                            <p>
                              Chez Go-tour, nous sommes convaincus que chaque
                              voyage est une expérience unique. C'est pourquoi
                              nous avons développé des fonctionnalités
                              exclusives pour vous permettre de personnaliser
                              votre voyage selon vos envies. Vous pouvez ainsi
                              choisir votre siège, ajouter des services
                              supplémentaires comme un repas ou un bagage
                              supplémentaire, et même sélectionner des options
                              de divertissement à bord. Avec Go-tour, vous êtes
                              sûr de vivre un voyage qui vous ressemble.
                            </p>
                          </Tab.Pane>
                        ),
                      },
                    ]}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Image
                    src={require("../../assets/images/description-2-app.png")}
                  />
                </Grid.Column>
              </Grid>
            </Container>
          </div>
          <div className="testimonials">
            <Container>
              <Testimonials />
            </Container>
          </div>
          <div className="statistics">
            <Container>
              <Statistic.Group stackable widths={5} size={"huge"}>
                <Statistic>
                  <Icon size="huge" color="violet" name="users" />
                  <Statistic.Value>231</Statistic.Value>
                  <Statistic.Label>Happy Users</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Icon size="huge" color="teal" name="code" />
                  <Statistic.Value>385</Statistic.Value>
                  <Statistic.Label>Issue Solved</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Icon size="huge" color="olive" name="cog" />
                  <Statistic.Value>159</Statistic.Value>
                  <Statistic.Label>Good Reviews</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Icon size="huge" color="orange" name="discussions" />
                  <Statistic.Value>127</Statistic.Value>
                  <Statistic.Label>Case Studies</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Icon size="huge" color="blue" name="rocket" />
                  <Statistic.Value>211</Statistic.Value>
                  <Statistic.Label>Order Received</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Container>
          </div>
          <div className="bottom-image-down">
            <Container>
              <Image
                centered
                src={require("../../../src/assets/images/download-iphone.png")}
              />
              <p>
                Planifiez vos voyages en toute simplicité, en choisissant vos
                dates et horaires de trajet!
              </p>
              <Button inverted color="google plus">
                <Icon name="google play" /> Télécharger
              </Button>
            </Container>
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
              <path
                fill="#06101f"
                fill-opacity="1"
                d="M0,192L34.3,181.3C68.6,171,137,149,206,154.7C274.3,160,343,192,411,181.3C480,171,549,117,617,117.3C685.7,117,754,171,823,176C891.4,181,960,139,1029,101.3C1097.1,64,1166,32,1234,42.7C1302.9,53,1371,107,1406,133.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
              ></path>
            </svg>
            <Segment
              inverted
              basic
              style={{
                textAlign: "center",
                marginTop: -10,
                background: "#06101f",
              }}
            >
              <Divider />
              <p>Powered by CRINTECK, tous droits reservés.</p>
            </Segment>
          </div>
        </GridRow>
      </GridRow>

      {menuShow && (
        <div className="mob-menu">
          <Menu stackable size="small" borderless>
            <Menu.Menu position="right">
              <Menu.Item name="messages" as={Link} content="Description" />
              <Menu.Item name="messages" as={Link} content="Fonctionnalités" />
              <Menu.Item name="messages" as={Link} content="Ecrans" />
              <Dropdown item text="Extra">
                <Dropdown.Menu>
                  <Dropdown.Item>Articles details</Dropdown.Item>
                  <Dropdown.Item>Termes et conditions</Dropdown.Item>
                  <Dropdown.Item>Privacy Policy</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Menu.Item>
                <Button as={Link} inverted to="connexion" primary>
                  Se connecter
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
      )}
    </Fragment>
  );
};

export default HomePage;
