import React from "react";
import {
  Button,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Table,
  Tab,
  Breadcrumb,
  Divider,
} from "semantic-ui-react";
import Page from "../../components/Page";
import TrajetsPaneContent from "./components/TrajetsPaneContent.js";
import VillesPaneContent from "./components/VillesPaneContent";

function RoutesPage() {
  return (
    <Page title="Routes">
      <Breadcrumb>
        <Breadcrumb.Section link>Home</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Routes</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <Tab
        panes={[
          {
            menuItem: "Trajets",
            render: () => (
              <Tab.Pane>
                <TrajetsPaneContent />
              </Tab.Pane>
            ),
          },
          {
            menuItem: "Villes",
            render: () => (
              <Tab.Pane>
                <VillesPaneContent />
              </Tab.Pane>
            ),
          },
        ]}
      />
    </Page>
  );
}

export default RoutesPage;
