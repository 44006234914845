import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Container, Grid, Header, Segment } from "semantic-ui-react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Jun",
    "Jul",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Revenues",
      data: [
        45000, 52000, 63000, 75000, 81000, 93000, 102000, 120000, 135000,
        150000, 162000, 180000,
      ],
      fill: false,
      borderColor: "#00b5ad",
      tension: 0.1,
    },
  ],
};

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: (value) => `FCFA ${value.toLocaleString()}`,
        },
      },
    ],
  },
  legend: {
    labels: {
      usePointStyle: true,
    },
  },
};

const RevenueChart = () => {
  return (
    <Segment style={{ height: "400px", width: "100%", paddingBottom: "50px" }}>
      <Header as="h3">Revenues</Header>
      <Line data={data} options={options} height={300} />
    </Segment>
  );
};


export default RevenueChart;
