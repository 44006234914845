import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Header,
  Input,
  Modal,
  TextArea,
} from "semantic-ui-react";
import { useFormik } from "formik";
import * as yup from "yup";
import * as busService from "../../services/busService";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  libelle: yup.string().min(4).max(255).required(),
  immatriculation: yup.string().min(4).max(50).required(),
  places: yup.number().min(10).required(),
  type_places: yup.string().oneOf(["SIMPLE", "MIXTE", "VIP"]),
  description: yup.string().max(255),
  active: yup.boolean(),
});

function BusFormModal({
  successCallback = () => {},
  closeCallback = () => {},
  open = false,
  setOpen,
  selectedItem,
}) {
  const fetchBus = () => {
    busService.fetchOne(selectedItem).then(
      (response) => {
        setFieldValue("libelle", response.data.libelle, false);
        setFieldValue("description", response.data.description, false);
        setFieldValue("immatriculation", response.data.immatriculation, false);
        setFieldValue("active", response.data.active === 1, false);
        setFieldValue("places", response.data.places, false);
        setFieldValue("type_places", response.data.type_places, false);
      },
      (reason) => {
        toast(
          `${
            reason?.response?.data ||
            "Une erreur s'est produite lors de la requête."
          }`,
          {
            type: "error",
            theme: "colored",
          }
        );
        setOpen(false);
      }
    );
  };
  const formik = useFormik({
    initialValues: {
      libelle: "",
      immatriculation: "",
      places: "",
      type_places: "",
      description: "",
      active: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const request = selectedItem
        ? busService.update(selectedItem, values)
        : busService.create(values);
      request
        .then(
          (response) => {
            toast(`Bus ${selectedItem ? "Modifié" : "ajouté"} avec succès.`, {
              type: "success",
              theme: "colored",
            });
            successCallback();
            handleReset();
            setOpen(false);
          },
          (reason) => {
            toast(
              `${
                reason?.response?.data ||
                "Une erreur s'est produite lors de la requête."
              }`,
              {
                type: "error",
                theme: "colored",
              }
            );
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    handleReset,
  } = formik;

  useEffect(() => {
    handleReset();
    if (selectedItem) {
      fetchBus();
    }
    return () => {};
  }, [selectedItem]);

  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      as={Form}
      size={"tiny"}
    >
      <Modal.Header>{`${
        selectedItem ? "Edition du bus" : "Ajouter un bus"
      }`}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={"libelle" in errors}>
            <label>Libelle</label>
            <Input
              value={values.libelle}
              onChange={handleChange("libelle")}
              onBlur={handleBlur("libelle")}
            />
            <small>{errors.libelle}</small>
          </Form.Field>
          <Form.Field required error={"immatriculation" in errors}>
            <label>Immatriculation</label>
            <Input
              value={values.immatriculation}
              onChange={handleChange("immatriculation")}
              onBlur={handleBlur("immatriculation")}
            />
            <small>{errors.immatriculation}</small>
          </Form.Field>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"places" in errors}>
              <label>Nombre de places</label>
              <Form.Input
                value={values.places}
                onChange={handleChange("places")}
                onBlur={handleBlur("places")}
              />
              <small>{errors.places}</small>
            </Form.Field>
            <Form.Field required error={"type_places" in errors}>
              <label>Type de places</label>
              <Form.Select
                value={values.type_places}
                onChange={(e, data) => {
                  setFieldValue("type_places", data.value);
                }}
                options={[
                  { key: "SIMPLE", text: "Simple", value: "SIMPLE" },
                  { key: "VIP", text: "VIP", value: "VIP" },
                  { key: "MiXTE", text: "Mixte", value: "MiXTE" },
                ]}
              />
              <small>{errors.type_places}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field error={"description" in errors}>
              <label>Description</label>
              <TextArea
                value={values.description}
                onChange={handleChange("description")}
                onBlur={handleBlur("description")}
              />
              <small>{errors.description}</small>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <Form.Checkbox
              onChange={(e, data) => setFieldValue("active", data.checked)}
              label="Activer le bus"
              checked={values.active}
            />
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          loading={isSubmitting}
          type="submit"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default BusFormModal;
