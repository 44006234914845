import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Outlet } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  Dropdown,
  Icon,
  Menu,
  Sidebar,
  SidebarPusher,
  Image,
} from "semantic-ui-react";
import Logo from "../assets/images/logo_type_login_redim.png";
import { authState } from "../atoms/auth";
import { removeUserFromStorage } from "../services/authService";
import "../styles/dashboard_layout.min.css";
import SidebarMenu from "./sidebarMenus/SidebarMenu";

const DashboardLayout = () => {
  const [authValue, setAuthValue] = useRecoilState(authState);

  const [visible, setVisible] = useState(false);
  const handleLogout = () => {
    removeUserFromStorage();
    setAuthValue(null);
  };
  return (
    <div className="DashboardLayout">
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        onHide={() => setVisible(false)}
        vertical
        visible={visible}
        width="thin"
        className="bg-teal-700"
      >
        <Menu.Item as="a">
          <Icon name="home" />
          Home
        </Menu.Item>
        <Menu.Item as="a">
          <Icon name="gamepad" />
          Games
        </Menu.Item>
        <Menu.Item as="a">
          <Icon name="camera" />
          Channels
        </Menu.Item>
      </Sidebar>
      <div className="pusher">
        <div className="full height">
          <div className="toc bg-white">
            <Menu fluid vertical>
              {<SidebarMenu />}
            </Menu>
          </div>
          <div className="page-content">
            <Menu fixed="top" secondary>
              <Menu.Item className="brand" as="a">
                <Image
                  alignSelf={"center"}
                  src={Logo}
                  className={"ui centered logo"}
                  alt="Logo imge"
                  width={50}
                  height={50}
                /> Go-Tour
              </Menu.Item>
              <Menu.Menu className="right desktop only">
                <Dropdown
                  item
                  text={`${authValue?.user?.first_name}`}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item>Mon profile</Dropdown.Item>
                    <Dropdown.Item>Déconnexion</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Menu.Item className="log-out" as="a" onClick={handleLogout}>
                  <Icon name="log out" />
                  Déconnexion
                </Menu.Item>
              </Menu.Menu>
              <div className="right menu mobile only">
                <Menu.Item as="a" onClick={() => setVisible(!visible)}>
                  <Icon name="bars" />
                </Menu.Item>
              </div>
            </Menu>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
