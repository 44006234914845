import React from "react";
import ComparativePerformanceChart from "./charts/company/ComparativePerformanceChart";
import GeoChart from "./charts/company/GeoChart";
import OccupancyChart from "./charts/company/OccupancyChart";
import RevenueChart from "./charts/company/RevenueChart";
import SalesChart from "./charts/company/SalesChart";

function CompanyDashboard() {
  return (
    <div>
      <SalesChart />
      <RevenueChart/>
      <OccupancyChart/>
      <GeoChart/>
      <ComparativePerformanceChart/>
    </div>
  );
}

export default CompanyDashboard;
