import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Pagination,
  Table,
} from "semantic-ui-react";
import CityFormModal from "../../../components/FormModals/CityFormModal";
import { fetchAll } from "../../../services/citiesService";
import { useRecoilValue } from "recoil";
import { authState } from "../../../atoms/auth";

function VillesPaneContent() {
  const [open, setOpen] = useState(false);
  const [cities, setCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalCities, setTotalCities] = useState();
  const [selectedCityId, setSelectedCityId] = useState();
  const authValue = useRecoilValue(authState);

  const fetchCities = async () => {
    try {
      const response = await fetchAll({
        page: currentPage,
        limit: 10,
        search: searchTerm,
      });
      if (response?.status === 200 && Array.isArray(response.data.data)) {
        setCities(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalCities(response.data.totalCities);
      }
    } catch (error) {}
  };

  const handleOpenEdit = (cityId) => {
    setSelectedCityId(cityId);
    setOpen(true);
  };

  useEffect(() => {
    fetchCities();
    return () => {};
  }, [currentPage, searchTerm]);

  useEffect(() => {
    if (open === false) {
      setSelectedCityId(null);
    }
    return () => {};
  }, [open]);

  return (
    <React.Fragment>
      <GridRow className="Routes">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          ></Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
               onChange={(e) => setSearchTerm(e.target.value)}
                name="search"
              />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Ville</Table.HeaderCell>
                <Table.HeaderCell>Actif</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {cities?.map((city, index) => (
                <Table.Row key={`${city.idcities}`}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{city.libelle}</Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      toggle
                      disabled={authValue?.user?.user_group !== "ADMIN"}
                      checked={city.active === 1}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown pointing="right" icon="ellipsis vertical">
                      <Dropdown.Menu>
                        <Dropdown.Item icon="eye" content="Détails" />
                        <Dropdown.Item
                          icon="edit"
                          content="Editer"
                          onClick={() => handleOpenEdit(city.idcities)}
                        />
                        <Dropdown.Item icon="trash" content="Supprimer" />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="6">
                  <Pagination
                    style={{ float: "right" }}
                    defaultActivePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(e, { activePage }) =>
                      setCurrentPage(activePage)
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      </GridRow>
      <CityFormModal
        open={open}
        selectedCityId={selectedCityId}
        setOpen={setOpen}
        successCallback={fetchCities}
      />
    </React.Fragment>
  );
}

export default VillesPaneContent;
