import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Icon, Menu } from "semantic-ui-react";
import { authState } from "../../atoms/auth";

function AdminMenus() {
  const [authValue, setAuthValue] = useRecoilState(authState);

  return (
    <React.Fragment>
      <Menu.Item as={Link} to="dashboard">
        <span>
          <Icon name="dashboard" /> Dashboard
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="compagnies">
        <span>
          <Icon name="building" /> Compagnies
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="routes">
        <span>
          <Icon name="map marker" /> Routes
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="users">
        <span>
          <Icon name="users" /> Utilisateurs
        </span>
      </Menu.Item>
      <Menu.Item as="a">
        <span>
          <Icon name="cogs" /> Paramètres
        </span>
        <Menu.Menu>
          <Menu.Item as={Link} to="configuration/ticket-de-transport">
            <span>
              <Icon name="ticket" /> Ticket de transport
            </span>
          </Menu.Item>
          <Menu.Item as={Link} to="configuration/parametres-de-paiement">
            <span>
              <Icon name="credit card" /> Paramètres de paiement
            </span>
          </Menu.Item>
          <Menu.Item as={Link} to="configuration/parametres-de-reservation">
            <span>
              <Icon name="calendar" /> Paramètres de réservation
            </span>
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      {/*  <Menu.Item as={Link} to="notifications">
        <span>
          <Icon name="bell" /> Notifications
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="messages">
        <span>
          <Icon name="envelope" /> Messages
        </span>
      </Menu.Item> */}
      <Menu.Item as="a">
        <span>
          <Icon name="user" /> Mon profile
        </span>
      </Menu.Item>
    </React.Fragment>
  );
}

export default AdminMenus;
