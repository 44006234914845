export const getHourFromDate = (date = "") => {
  try {
    let dateTimeParts = date.split("T");
    let timePart = dateTimeParts[1];
    return timePart.substring(0, 5);
  } catch (error) {
    return null;
  }
};

export const is_admin = (authValue = {}) => {
  return authValue?.user?.user_group === "ADMIN";
};

export function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
