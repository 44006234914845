import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  Pagination,
  Table,
} from "semantic-ui-react";
import UserFormModal from "../../components/FormModals/UserFormModal";
import Page from "../../components/Page";
import * as usersService from "../../services/usersService";

function UsersPage() {
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();

  const fetchUsers = async () => {
    try {
      const response = await usersService.fetchAll({
        page: currentPage,
        limit: 10,
        search: searchTerm,
      });
      if (response?.status === 200 && Array.isArray(response.data.data)) {
        setUsers(response.data.data);
        setTotalUsers(response.data.totalUsers);
        setTotalPages(response.data.totalPages);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchUsers();

    return () => { };
  }, [searchTerm, currentPage]);

  const handleOpenEdit = (userId) => {
    setSelectedUserId(userId);
    setOpen(true);
  };

  useEffect(() => {
    if (open === false) {
      setSelectedUserId(null);
    }
    return () => { };
  }, [open]);

  return (
    <Page title="Utilisateurs">
      <Breadcrumb>
        <Breadcrumb.Section href="/dashboard">Dashboard</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Utilisateurs</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <GridRow className="Compagnies">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des Utilisateurs  [{totalUsers || 0}]</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                name="search"
              />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Genre</Table.HeaderCell>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Prénom</Table.HeaderCell>
                <Table.HeaderCell>Date Joined</Table.HeaderCell>
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.HeaderCell>Groupe</Table.HeaderCell>
                <Table.HeaderCell>Actions </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {users?.map((user, index) => (
                <Table.Row key={`${user.idusers}`}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{user.gender}</Table.Cell>
                  <Table.Cell>{user.last_name}</Table.Cell>
                  <Table.Cell>{user.first_name}</Table.Cell>
                  <Table.Cell>
                    {dayjs(user.created_at).format("DD-MM-YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    {user.email}{" "}
                    <Label
                      circular
                      empty
                      color={user?.active === 1 ? "green" : "red"}
                    />
                  </Table.Cell>
                  <Table.Cell>{user.phone}</Table.Cell>
                  <Table.Cell>{user.user_group}</Table.Cell>

                  <Table.Cell textAlign="right">
                    <Dropdown pointing="right" icon="ellipsis vertical">
                      <Dropdown.Menu>
                        <Dropdown.Item icon="eye" content="Détails" />
                        <Dropdown.Item
                          icon="edit"
                          onClick={() => handleOpenEdit(user.idusers)}
                          content="Editer"
                        />
                        <Dropdown.Item icon="trash" content="Supprimer" />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="9">
                  <Pagination
                    style={{ float: "right" }}
                    defaultActivePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(e, { activePage }) =>
                      setCurrentPage(activePage)
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      </GridRow>
      <UserFormModal
        open={open}
        setOpen={setOpen}
        successCallback={fetchUsers}
        selectedUserId={selectedUserId}
      />
    </Page>
  );
}

export default UsersPage;
