import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  List,
  Segment,
  Table,
} from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import CompanyFormModal from "../../components/FormModals/CompanyFormModal";
import Page from "../../components/Page";
import * as compagniesService from "../../services/compagniesService";
import AddUserFormModal from "../../components/FormModals/AddUserFormModal";
import AgencyFormModal from "../../components/FormModals/AgencyFormModal";
import { useUserType } from "../../hooks/useUserType";

function CompagniesDetails() {
  const [compagnies, setCompagnies] = useState([]);
  const [company, setCompany] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState();
  const [selectedAgencyId, setSelectedAgencyId] = useState();
  const [open, setOpen] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openAgencyModal, setOpenAgencyModal] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const userType = useUserType()

  const fetchCompany = async () => {
    const response = await compagniesService.fetchOne(params?.id);
    if (response.status === 200) {
      setCompany(response.data);
    }
  };

  useEffect(() => {
    fetchCompany();
    return () => { };
  }, []);

  useEffect(() => {
    if (open === false) {
      setSelectedCompanyId(null);
    }
    return () => { };
  }, [open]);
  useEffect(() => {
    if (openAgencyModal === false) {
      setSelectedAgencyId(null);
    }
    return () => { };
  }, [openAgencyModal]);

  const handleOpenEdit = (companyId) => {
    setSelectedCompanyId(companyId);
    setOpen(true);
  };

  const handleCloseModal = (companyId) => {
    setSelectedCompanyId(null);
  };

  const handleOpenAgencyEditModal = (agencyId) => {
    setSelectedAgencyId(agencyId);
    setOpenAgencyModal(true);
  };

  const handleCloseAgencyEditModal = (agencyId) => {
    setSelectedAgencyId(null);
  };

  return (
    <Page title="Détails de la compagnie">

      <Breadcrumb>
        <Breadcrumb.Section link onClick={() => navigate("/compagnies")}>
          Compagnies
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>
          {company?.denomination}
        </Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <GridRow as={Segment}>
        <Label ribbon>{company?.denomination}</Label>
        <Grid stackable columns={3} style={{ marginTop: "5px" }}>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Icon name="tag" />
                <List.Content>
                  <List.Header as="a">Dénomination</List.Header>
                  <List.Description>{company?.denomination}</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="tag" />
                <List.Content>
                  <List.Header as="a">Contacts</List.Header>
                  <List.Description>
                    {company?.phone_1 || "-"}/{company?.phone_2 || "-"}
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Icon name="tag" />
                <List.Content>
                  <List.Header as="a">Siège Social</List.Header>
                  <List.Description>{company?.siege_social}</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="tag" />
                <List.Content>
                  <List.Header as="a">Email</List.Header>
                  <List.Description>
                    {company?.email || "Non défini"}
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Icon name="tag" />
                <List.Content>
                  <List.Header as="a">RCCM/</List.Header>
                  <List.Description>
                    {company?.rccm || "Non défini"}
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="tag" />
                <List.Content>
                  <List.Header as="a">Web/</List.Header>
                  <List.Description>
                    {company?.web || "Non défini"}
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </GridRow>
      <GridRow className="CompagniesDetails">
        <Segment>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des utilisateurs</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
              <Button
                style={{ marginLeft: "10px" }}
                circular
                icon={<Icon name="add" />}
                onClick={() => setOpenUserModal(true)}
              />
            </Grid.Column>
          </Grid>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Nom & Prénom</Table.HeaderCell>
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                <Table.HeaderCell>Téléphone</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {company?.users_compagnies?.map((user_company, index) => (
                <Table.Row key={`${user_company.idusers_company}`}>
                  <Table.Cell>{`${index + 1}`}</Table.Cell>
                  <Table.Cell>
                    {user_company?.users.first_name}{" "}
                    {user_company?.users.last_name}
                  </Table.Cell>
                  <Table.Cell>{user_company?.users.email}</Table.Cell>
                  <Table.Cell>{user_company?.users.phone}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown pointing="right" icon="ellipsis vertical">
                      <Dropdown.Menu>
                        <Dropdown.Item icon="eye" content="Détails" />
                        <Dropdown.Item icon="edit" content="Editer" />
                        <Dropdown.Item icon="trash" content="Supprimer" />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
        <Segment>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des agences</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
              <Button
                style={{ marginLeft: "10px" }}
                circular
                icon={<Icon name="add" />}
                onClick={() => setOpenAgencyModal(true)}
              />
            </Grid.Column>
          </Grid>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Dénomination</Table.HeaderCell>
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                <Table.HeaderCell>Téléphone</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {company?.agencies?.map((agency, index) => (
                <Table.Row key={`${agency.idagencies}`}>
                  <Table.Cell>{`${index + 1}`}</Table.Cell>
                  <Table.Cell>{agency.denomination}</Table.Cell>
                  <Table.Cell>{agency.email}</Table.Cell>
                  <Table.Cell>{agency.phone}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown pointing="right" icon="ellipsis vertical">
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon="eye"
                          content="Détails"
                          onClick={() => navigate(`/${userType === "ADMIN" ? "admin" : "company"}/agencies/${agency.uuid}`)}
                        />
                        <Dropdown.Item
                          icon="edit"
                          content="Editer"
                          onClick={() =>
                            handleOpenAgencyEditModal(agency.idagencies)
                          }
                        />
                        <Dropdown.Item icon="trash" content="Supprimer" />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </GridRow>
      <AddUserFormModal
        setOpen={setOpenUserModal}
        open={openUserModal}
        closeCallback={handleCloseModal}
        successCallback={fetchCompany}
        objectId={company?.idcompagnies}
      />
      <AgencyFormModal
        setOpen={setOpenAgencyModal}
        open={openAgencyModal}
        closeCallback={handleCloseAgencyEditModal}
        successCallback={fetchCompany}
        selectedAgencyId={selectedAgencyId}
      />
    </Page>
  );
}

export default CompagniesDetails;
