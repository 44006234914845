import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { Container, Grid, Header, Segment } from "semantic-ui-react";
import { getRandomColor } from "../../../utils/commonsHelper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: ["Nord", "Sud", "Est", "Ouest"],
  datasets: [
    {
      label: "Répartition géographique",
      data: [30, 25, 20, 25],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
    },
  ],
};

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          suggestedMax: 100,
        },
      },
    ],
  },
  legend: {
    labels: {
      usePointStyle: true,
    },
  },
};

const GeoChart = () => {
  return (
    <Segment style={{ height: "400px", width: "100%", paddingBottom: "50px" }}>
      <Header as="h3">Revenues</Header>
      <Pie data={data} options={options} height={300} />
    </Segment>
  );
};

export default GeoChart;
