import { Grid, Header, Form, Segment, Message } from "semantic-ui-react";

import dayjs from "dayjs";

function TravelsParamsForm({ routes, formik, handleRecursiveDaysChange }) {
  const {
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
  } = formik;
  return (
    <Form as={Segment}>
      <Grid>
        <Grid.Column width={8}>
          <Message>
            Utilisez ce formulaire pour faire la programmation de vos voyages.
            Ces voyages apparaitrons comme tickets unique à reserver. Il est
            preférable de faire une programmation sur une longue période.
          </Message>
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Field required error={"idroutes" in errors}>
            <label>Trajet</label>
            <Form.Select
              options={routes?.map((route) => ({
                key: `${route.idroutes}`,
                text: `${route.cities_routes_iddepartureTocities.libelle} - ${route.cities_routes_idarrivalTocities.libelle}`,
                value: route.idroutes,
              }))}
              value={values.idroutes}
              onChange={(e, data) => setFieldValue("idroutes", data.value)}
            />
            <small className="field-error">{errors.idroutes}</small>
          </Form.Field>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"startDate" in errors}>
              <label>Du</label>
              <Form.Input
                type="date"
                min={dayjs().format("YYYY-MM-DD")}
                value={values.startDate}
                onChange={handleChange("startDate")}
              />
              <small className="field-error">{errors.startDate}</small>
            </Form.Field>
            <Form.Field required error={"endDate" in errors}>
              <label>Au</label>
              <Form.Input
                type="date"
                min={dayjs().format("YYYY-MM-DD")}
                value={values.endDate}
                onChange={handleChange("endDate")}
              />
              <small className="field-error">{errors.endDate}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal" }>
            <Form.Field required error={"startHour" in errors}>
              <label>Heure de départ</label>
              <Form.Input
                type="time"
                value={values.startHour}
                onChange={handleChange("startHour")}
              />
              <small className="field-error">{errors.startHour}</small>
            </Form.Field>
            <Form.Field required error={"startHour" in errors}>
              <label>Montant</label>
              <Form.Input
                type="number"
                value={values.price}
                min={0}
                onChange={handleChange("price")}
              />
              <small className="field-error">{errors.price}</small>
            </Form.Field>
          </Form.Group>
          <Header content="Répétition" size="tiny" />
          <Form.Checkbox
            value={1}
            onChange={handleRecursiveDaysChange}
            label="Tous les lundi"
            checked={values.daysOfWeek?.includes(1)}
          />
          <Form.Checkbox
            value={2}
            onChange={handleRecursiveDaysChange}
            label="Tous les Mardi"
            checked={values.daysOfWeek?.includes(2)}
          />
          <Form.Checkbox
            value={3}
            onChange={handleRecursiveDaysChange}
            label="Tous les Mercredi"
            checked={values.daysOfWeek?.includes(3)}
          />
          <Form.Checkbox
            value={4}
            onChange={handleRecursiveDaysChange}
            label="Tous les Jeudi"
            checked={values.daysOfWeek?.includes(4)}
          />
          <Form.Checkbox
            value={5}
            onChange={handleRecursiveDaysChange}
            label="Tous les Vendredi"
            checked={values.daysOfWeek?.includes(5)}
          />
          <Form.Checkbox
            value={6}
            onChange={handleRecursiveDaysChange}
            label="Tous les Samedi"
            checked={values.daysOfWeek?.includes(6)}
          />
          <Form.Checkbox
            value={0}
            onChange={handleRecursiveDaysChange}
            label="Tous les Dimanche"
            checked={values.daysOfWeek?.includes(0)}
          />
        </Grid.Column>
      </Grid>
      <Form.Field style={{ textAlign: "right" }}>
        <Form.Button
          type="submit"
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          Enregistrer
        </Form.Button>
      </Form.Field>
    </Form>
  );
}

export default TravelsParamsForm;
