export const USER_STORAGE = "PazFUWta0QZg1Mv5cHpifUzw9RLNOdwq";
export const USER_GROUPS = {
  ADMIN: "ADMIN",
  CUSTOMER: "CUSTOMER",
  PARTNER: "PARTNER",
};
export const GENDER_LIST = {
  HOMME: "HOMME",
  FEMME: "FEMME",
  AUTRE: "AUTRE",
};

export const DAYS_OF_WEEK = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
];
