import React, { useEffect, useState } from "react";
import {
    Breadcrumb,
    Button,
    Divider,
    Dropdown,
    Grid,
    GridRow,
    Header,
    Message,
    Segment,
} from "semantic-ui-react";
import Page from "../../components/Page";
import * as configurationService from "../../services/configurationService";
import { toast } from "react-toastify";


function Tickets() {

    const [config, setConfig] = useState({});

    const handleUpdateJoursAvantFermetureVente = async (data) => {
        try {
            const res = await configurationService.updateJoursAvantFermetureVente(data);
            toast.success("La configuration a été mise à jour avec succès");
            setConfig(res.data);
        } catch (error) {

            toast.error("Une erreur est survenue lors de la mise à jour de la configuration");

        }
    }

    useEffect(() => {
        configurationService.fetchConfig().then((res) => {
            setConfig(res.data);
        });
    }, []);



    return (
        <Page title="Configuration | Ticket de transport">
            <Breadcrumb>
                <Breadcrumb.Section href="/dashboard">Dashboard</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section href="/configuration/ticket-de-transport">Configuration</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active>Ticket de transport</Breadcrumb.Section>
            </Breadcrumb>

            <Divider />

            <GridRow as={Segment} >
                <Header dividing as="h4">Jours avant fermeture de vente des tickets</Header>
                <Grid stackable>
                    <Grid.Column
                        width={8}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <Message>
                            <Message.Header>Note!</Message.Header>
                            <p>Veuillez indiquer le nombre de jours avant la fermeture
                                de la vente des tickets. Cette information nous permettra
                                de mieux gérer les délais de traitement des commandes et
                                de garantir une expérience d'achat optimale pour nos clients.
                            </p>
                        </Message>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Dropdown
                            placeholder='Choisir le nombre de jours'
                            fluid
                            search
                            selection
                            onChange={(e, data) => handleUpdateJoursAvantFermetureVente({ jours_avant_fermeture_vente: data.value })}
                            value={config?.jours_avant_fermeture_vente}
                            options={[
                                { key: 1, text: '1 jour', value: 1 },
                                { key: 2, text: '2 jours', value: 2 },
                                { key: 3, text: '3 jours', value: 3 },
                                { key: 4, text: '4 jours', value: 4 },
                                { key: 5, text: '5 jours', value: 5 },
                                { key: 6, text: '6 jours', value: 6 },
                                { key: 7, text: '7 jours', value: 7 },
                            ]}
                        />
                    </Grid.Column>

                </Grid>
            </GridRow>

        </Page>
    )
}

export default Tickets