import axios from "axios";
import { USER_STORAGE } from "../constants";

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://go-tour.crinteck.com/api/"
      : `http://localhost:5000/api/`,
});

api.interceptors.request.use((config) => {
  try {
    const userData = JSON.parse(sessionStorage.getItem(USER_STORAGE) || "");
    config.headers["x-access-token"] = userData?.token;
    return config;
  } catch (error) {
    return config;
  }
});
