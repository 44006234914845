import { api } from "../axios/api";

export const create = async (data) => {
  return api.post(`users/admin/create`, data);
};

export const update = async (id,data) => {
  return api.put(`users/admin/update/${id}`, data);
};

export const fetchAll = async (params) => {
  return api.get(`users`, {params});
};

export const fetchOne = async (id) => {
  return api.get(`users/${id}`);
};

export const updateMe = async (id, data) => {
  return api.put(`users/${id}`, data);
};
