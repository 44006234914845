import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Pagination,
  Table,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import CompanyFormModal from "../../components/FormModals/CompanyFormModal";
import Page from "../../components/Page";
import * as compagniesService from "../../services/compagniesService";

function CompagniesList() {
  const [compagnies, setCompagnies] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const fetchCompagnies = async () => {
    const response = await compagniesService.fetchAll({
      page: currentPage,
      limit: 10,
      search: searchTerm,
    });
    if (response.status === 200 && Array.isArray(response.data.data)) {
      setCompagnies(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotalCompanies(response.data.totalCompanies);
    }
  };

  useEffect(() => {
    fetchCompagnies();
    return () => { };
  }, [currentPage, searchTerm]);

  useEffect(() => {
    if (open === false) {
      setSelectedCompanyId(null);
    }
    return () => { };
  }, [open]);

  const handleOpenEdit = (companyId) => {
    setSelectedCompanyId(companyId);
    setOpen(true);
  };

  const handleCloseModal = (companyId) => {
    setSelectedCompanyId(null);
  };

  return (
    <Page title="Compagnies">
      <Breadcrumb>
        <Breadcrumb.Section link>Home</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Compagnies</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <GridRow className="Compagnies">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des compagnies [{totalCompanies || 0}]</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
                name="search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Dénomination</Table.HeaderCell>
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                <Table.HeaderCell>Téléphone</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {compagnies?.map((company, index) => (
                <Table.Row key={`${company.idcompagnies}`}>
                  <Table.Cell>{`${index + 1}`}</Table.Cell>
                  <Table.Cell>{company.denomination}</Table.Cell>
                  <Table.Cell>{company.email}</Table.Cell>
                  <Table.Cell>{company.phone}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown pointing="right" icon="ellipsis vertical">
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon="eye"
                          content="Détails"
                          onClick={() => navigate(`${company.uuid}`)}
                        />
                        <Dropdown.Item
                          icon="edit"
                          content="Editer"
                          onClick={() => handleOpenEdit(company.idcompagnies)}
                        />
                        <Dropdown.Item icon="trash" content="Supprimer" />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="6">
                  <Pagination
                    style={{ float: "right" }}
                    defaultActivePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(e, { activePage }) =>
                      setCurrentPage(activePage)
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      </GridRow>
      <CompanyFormModal
        setOpen={setOpen}
        open={open}
        selectedCompanyId={selectedCompanyId}
        closeCallback={handleCloseModal}
        successCallback={fetchCompagnies}
      />
    </Page>
  );
}

export default CompagniesList;
