import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Pagination,
  Table,
} from "semantic-ui-react";
import BusFormModal from "../../components/FormModals/BusFormModal";
import Page from "../../components/Page";
import * as busService from "../../services/busService";
import dayjs from "dayjs";

function BusListPage() {
  const [buses, setBuses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalBuses, setTotalBuses] = useState(0);
  const [selectedBusId, setSelectedBusId] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const fetchBuses = async () => {
    const response = await busService.fetchAll({
      page: currentPage,
      limit: 10,
      search: searchTerm,
    });
    if (response.status === 200 && Array.isArray(response.data.data)) {
      setBuses(response.data.data);
      setTotalBuses(response.data.totalBuses);
      setTotalPages(response.data.totalPages);
    }
  };

  useEffect(() => {
    fetchBuses();
    return () => { };
  }, [searchTerm, currentPage]);

  useEffect(() => {
    if (open === false) {
      setSelectedBusId(null);
    }
    return () => { };
  }, [open]);

  const handleOpenEdit = (companyId) => {
    setSelectedBusId(companyId);
    setOpen(true);
  };

  const handleCloseModal = (companyId) => {
    setSelectedBusId(null);
  };

  return (
    <Page title="Liste des bus">
      <Breadcrumb>
        <Breadcrumb.Section>Accueil</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Bus
        </Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <GridRow className="BusListPage">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des bus [{totalBuses || 0}]</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                name="search"
              />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Libelle</Table.HeaderCell>
                <Table.HeaderCell>Immatriculation</Table.HeaderCell>
                <Table.HeaderCell>Type de places</Table.HeaderCell>
                <Table.HeaderCell>Places</Table.HeaderCell>
                <Table.HeaderCell>Date Création</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {buses?.map((bus, index) => (
                <Table.Row key={`${bus.idbus}`}>
                  <Table.Cell>{`${index + 1}`}</Table.Cell>
                  <Table.Cell>{bus.libelle}</Table.Cell>
                  <Table.Cell>{bus.immatriculation}</Table.Cell>
                  <Table.Cell>{bus.type_places}</Table.Cell>
                  <Table.Cell>{bus.places}</Table.Cell>
                  <Table.Cell>
                    {dayjs(bus.created_at).format("DD-MM-YYYY")}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown pointing="right" icon="ellipsis vertical">
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon="edit"
                          content="Editer"
                          onClick={() => handleOpenEdit(bus.idbus)}
                        />
                        <Dropdown.Item icon="trash" content="Supprimer" />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="9">
                  <Pagination
                    style={{ float: "right" }}
                    defaultActivePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(e, { activePage }) =>
                      setCurrentPage(activePage)
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      </GridRow>
      <BusFormModal
        setOpen={setOpen}
        open={open}
        selectedItem={selectedBusId}
        closeCallback={handleCloseModal}
        successCallback={fetchBuses}
      />
    </Page>
  );
}

export default BusListPage;
