import { useRecoilValue } from "recoil";
import { authState } from "../atoms/auth";
import { getUserFromStorage } from "../services/authService";

export const useUserType = () => {
    const authStore = useRecoilValue(authState);
    const user = authStore?.user || getUserFromStorage()?.user;
    return user?.user_group;
}




