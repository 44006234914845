import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  Breadcrumb,
  Button,
  Card,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Pagination,
  Table,
} from "semantic-ui-react";
import { authState } from "../../atoms/auth";
import Page from "../../components/Page";

import * as ticketsService from "../../services/ticketsService";
import ReservationCard from "../../components/ReservationCard";

function BookingsList() {
  const [tickets, setTickets] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalTickets, setTotalTickets] = useState(0);
  const userData = useRecoilValue(authState);

  const fetchTickets = async () => {
    try {
      const response = await ticketsService[
        `${userData?.user_group === "ADMIN"
          ? ""
          : userData?.user.user_type === "is_company_user"
            ? "getCompanyTickets"
            : "getAgencyTickets"
        }`
      ]({
        page: currentPage,
        limit: 10,
        search: searchTerm,
      });

      if (response.data && Array.isArray(response.data.data)) {
        setTickets(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalTickets(response.data.totalTickets);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchTickets();
    return () => { };
  }, [searchTerm, currentPage]);

  return (
    <Page title="BookingsList">
      <Breadcrumb>
        <Breadcrumb.Section link>Accueil</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Réservations</Breadcrumb.Section>

      </Breadcrumb>
      <Divider />
      <GridRow className="BookingsList">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des réservations [{totalTickets}]</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search" >
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                name="search"
              />
            </Input>

            {userData?.user.user_type === "is_agency_user" && (
              <>
                <Button
                  style={{ marginLeft: "10px" }}
                  circular
                  icon={<Icon name="add" />}
                />
              </>
            )}
          </Grid.Column>
          <Grid.Column width={16}>
            <Card.Group itemsPerRow={2} stackable>
              {tickets?.map(reservation => (
                <ReservationCard key={reservation.id} reservation={reservation} />
              ))}
            </Card.Group>
            <Pagination
              style={{ float: "right", marginTop: "10px" }}
              defaultActivePage={currentPage}
              totalPages={totalPages}
              onPageChange={(e, { activePage }) =>
                setCurrentPage(activePage)
              }
            />
          </Grid.Column>
        </Grid>

      </GridRow>
    </Page>
  );
}

export default BookingsList;
