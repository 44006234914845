import { useState } from "react";
import {
  Button,
  Grid,
  GridRow,
  Image,
  Form,
  Input,
  Header,
  Icon,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as authService from "../../services/authService";
import Logo from "../../assets/images/logo_type_login_redim.png";
import { useNavigate } from "react-router-dom";
import "../../styles/loginPage.css";
import { useRecoilState } from "recoil";
import { authState } from "../../atoms/auth";
import { Helmet } from "react-helmet";

const validationSchema = yup.object().shape({
  login: yup.string().min(8).max(64).required(),
  password: yup.string().min(8).max(64).required(),
});

const LoginPage = () => {
  const [authValue, setAuthValue] = useRecoilState(authState);
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      login: "admin@gmail.com",
      password: "password",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      authService
        .login(values)
        .then(
          (response) => {
            setAuthValue(response.data);
            authService.saveUserFromStorage(response.data);

            if (response.data.user?.user_group === "ADMIN") {
              return navigate(`/admin/dashboard`);
            }
            if (response.data.user?.users_compagnies?.idusers_compagnies) {
              return navigate(`/company/dashboard`);
            }
            if (response.data.user?.users_agencies?.agencies) {
              return navigate(`/agency/dashboard`);
            }
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;
  return (
    <GridRow className="LoginPage">
      <Helmet>
        <title>Connexion</title>
      </Helmet>
      <Grid style={{ height: "100%" }}>
        <Grid.Column className="left-box">
          <Header as={"h1"}>
            Votre plateforme de mise en vente des tickets de transport.
          </Header>
          <Button>Devenez partenaire</Button>
        </Grid.Column>
        <Grid.Column className="right-box">
          <Grid.Row>
            <Image
              alignSelf={"center"}
              src={Logo}
              className={"ui centered logo"}
              alt="Logo imge"
            />
            <Header as={"h6"} className="text center" color={"grey"}>
              Connectez vous à votre espace d'administration.
            </Header>
            <Form>
              <Form.Field required error={"login" in errors}>
                <label>Login</label>
                <Input
                  value={values.login}
                  onChange={handleChange("login")}
                  onBlur={handleBlur("login")}
                  type="text"
                />
                <small className="field-error">{errors.login}</small>
              </Form.Field>
              <Form.Field required error={"password" in errors}>
                <label>Mot de passe</label>
                <Input
                  value={values.password}
                  onChange={handleChange("password")}
                  onBlur={handleBlur("password")}
                  type={secureTextEntry ? "password" : "text"}
                  icon={
                    <Icon
                      name={secureTextEntry ? "eye" : "eye slash"}
                      link
                      onClick={() => setSecureTextEntry(!secureTextEntry)}
                    />
                  }
                />
                <small className="field-error">{errors.password}</small>
              </Form.Field>
              <Button
                type="submit"
                loading={isSubmitting}
                onClick={handleSubmit}
                color="teal"
                fluid
              >
                Se connecter
              </Button>
            </Form>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </GridRow>
  );
};

export default LoginPage;
