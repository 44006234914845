import React from "react";
import { authState } from "../../atoms/auth";
import { useRecoilState } from "recoil";
import AdminMenus from "./AdminMenus";
import { USER_GROUPS } from "../../constants";
import CompanyMenus from "./CompanyMenus";
import AgencyMenus from "./AgencyMenus";

function SidebarMenu() {
  const [authValue, setAuthValue] = useRecoilState(authState);

  const getUserMenus = () => {
    if (authValue?.user?.user_group === USER_GROUPS.ADMIN) {
      return <AdminMenus />;
    }
    if (authValue?.user?.users_compagnies?.idusers_compagnies) {
      return <CompanyMenus />;
    }
    if (authValue?.user?.users_agencies?.agencies) {
      return <AgencyMenus />;
    }
  };

  return getUserMenus();
}

export default SidebarMenu;
