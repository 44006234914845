import { api } from "../axios/api";
import { USER_STORAGE } from "../constants";

export const login = async (data) => {
  return api.post(`auth/login`, data);
};

export const getUserFromStorage = () => {
  try {
    return JSON.parse(sessionStorage.getItem(USER_STORAGE));
  } catch (error) {
    return null;
  }
};

export const saveUserFromStorage = (data) => {
  sessionStorage.setItem(USER_STORAGE, JSON.stringify(data));
};

export const removeUserFromStorage = () => {
  sessionStorage.removeItem(USER_STORAGE);
};
