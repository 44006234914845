import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  List,
  Segment,
  Table,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Page from "../../components/Page";
import * as agenciesService from "../../services/agenciesService";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/auth";
import AddAgencyUserFormModal from "../../components/FormModals/AddAgencyUserFormModal";


function AgenceDetails() {
  const [agency, setAgency] = useState();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openAgencyModal, setOpenAgencyModal] = useState(false);
  const authValue = useRecoilValue(authState);

  const params = useParams();

  const fetchAgency = async () => {
    try {
      const response = await agenciesService.fetchOne(
        params?.id || ""
      );

      if (response?.status === 200) {
        setAgency(response?.data);
      }
    } catch (error) { }
  };
  useEffect(() => {
    /*  if (params?.uuid) {
     
    } */ fetchAgency();
    return () => { };
  }, [params?.id]);

  return (
    <Page title="Détail de l'agence">
      <GridRow className="IntermediaireAgencyDetails">
        <Breadcrumb>
          <Breadcrumb.Section link>Accueil</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link>Agences</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>
            {agency?.denomination}
          </Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <GridRow as={Segment}>
          <Label ribbon>{agency?.denomination}</Label>
          <Grid stackable columns={3} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Dénomination</List.Header>
                    <List.Description>{agency?.denomination}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Contacts</List.Header>
                    <List.Description>
                      {agency?.phone}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Adresse</List.Header>
                    <List.Description>{agency?.address}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Email</List.Header>
                    <List.Description>{agency?.email}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </GridRow>
        <GridRow as={Segment}>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des utilisateurs</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
              <Button
                style={{ marginLeft: "10px" }}
                circular
                icon={<Icon name="add" />}
                onClick={() => setOpenUserModal(true)}
              />
            </Grid.Column>
          </Grid>

          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Prénom</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Téléphones</Table.HeaderCell>
                <Table.HeaderCell>Admin</Table.HeaderCell>
                <Table.HeaderCell>Actif</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {agency?.users_agencies?.map(
                (user_agency, index) => {
                  return (
                    <Table.Row key={`${user_agency.idintermediaires_agencies}`}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>{user_agency?.users?.last_name}</Table.Cell>
                      <Table.Cell>{user_agency?.users?.first_name}</Table.Cell>
                      <Table.Cell>{user_agency?.users?.email}</Table.Cell>
                      <Table.Cell>{user_agency?.users?.phone}</Table.Cell>
                      <Table.Cell>{`${user_agency?.is_admin === 1 ? "OUI" : "NON"
                        }`}</Table.Cell>
                      <Table.Cell>{`${user_agency?.users?.active === 1 ? "OUI" : "NON"
                        }`}</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                            />
                            <Dropdown.Item
                              icon={<Icon name="edit" />}
                              content="Editer"
                            />
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Supprimer"
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                }
              )}
            </Table.Body>
          </Table>
        </GridRow>
      </GridRow>
      <AddAgencyUserFormModal
        setOpen={setOpenUserModal}
        open={openUserModal}
        agencyId={agency?.idagencies}
        callback={fetchAgency}
      />
    </Page>
  );
}

export default AgenceDetails;
