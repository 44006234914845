import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as routesService from "../../services/routesService";
import * as citiesService from "../../services/citiesService";

const validationSchema = yup.object().shape({
  idarrival: yup
    .number()
    .required()
    .notOneOf([yup.ref("departure"), null], "Doit être différent du départ."),
  iddeparture: yup
    .number()
    .required()
    .notOneOf([yup.ref("arrival"), null], "Doit être différent de l'arrivée."),
  minutes: yup.number().required().min(0).max(59),
  hours: yup.number().required().min(0),
  active: yup.boolean(),
});

function RouteFormModal({
  callback = () => {},
  successCallback = () => {},
  open = false,
  setOpen = () => {},
  selectedRouteId = null,
}) {
  const [cities, setCities] = useState([]);
  const [route, setRoute] = useState([]);

  const formik = useFormik({
    initialValues: {
      hours: 0,
      minutes: 0,
      idarrival: "",
      iddeparture: "",
      active: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const request = selectedRouteId
        ? routesService.update(selectedRouteId, values)
        : routesService.create(values);
      request
        .then(
          (response) => {
            toast(
              `Trajet ${selectedRouteId ? "Modifié" : "ajouté"} avec succès.`,
              {
                type: "success",
                theme: "colored",
              }
            );
            handleReset();
            successCallback();
            setOpen(false);
          },
          (reason) => {
            setSubmitting(false);
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;

  const fetchCities = async () => {
    try {
      const response = await citiesService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        let options = [
          {
            key: "DEFAULT",
            text: "Choisir...",
            value: "",
          },
        ];
        for (const city of response.data) {
          options.push({
            key: `${city.idcities}`,
            text: `${city.libelle}`,
            value: city.idcities,
          });
        }
        setCities(options);
      }
    } catch (error) {}
  };

  const fetchRoute = async () => {
    try {
      const response = await routesService.fetchOne(selectedRouteId);
      if (response.status === 200) {
        setFieldValue("idarrival", response.data?.idarrival, false);
        setFieldValue("iddeparture", response.data?.iddeparture, false);
        setFieldValue("hours", response.data?.hours, false);
        setFieldValue("minutes", response.data?.minutes, false);
        setRoute(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    formik.resetForm();
    fetchCities();
    return () => {};
  }, []);
  useEffect(() => {
    if (selectedRouteId) {
      fetchRoute();
    }
    return () => {};
  }, [selectedRouteId]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>{`${
        selectedRouteId ? "Edition d'un trajet" : "Ajouter un trajet"
      }`}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {errors.idcompagnies && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.idcompagnies}
            </div>
          )}
          <Form.Field error={"iddeparture" in errors} required>
            <label>Départ</label>
            <Form.Select
              scrolling
              search
              fluid
              onChange={(e, data) => setFieldValue("iddeparture", data.value)}
              options={cities}
              placeholder="Choisr..."
              value={values.iddeparture}
            />
            <small className="field-error">{errors.iddeparture}</small>
          </Form.Field>
          <Form.Field error={"idarrival" in errors} required>
            <label>Arrivée</label>
            <Form.Select
              scrolling
              search
              fluid
              onChange={(e, data) => setFieldValue("idarrival", data.value)}
              options={cities}
              placeholder="Choisr..."
              value={values.idarrival}
            />
            <small className="field-error">{errors.idarrival}</small>
          </Form.Field>

          <Form.Group>
            <Form.Field error={"hours" in errors} required width={6}>
              <label>Heures</label>
              <Form.Input
                min={0}
                type="number"
                value={values.hours}
                onChange={handleChange("hours")}
                onBlur={handleBlur("hours")}
              />
              <small className="field-error">{errors.hours}</small>
            </Form.Field>
            <Form.Field error={"minutes" in errors} required width={6}>
              <label>Minutes</label>
              <Form.Input
                min={0}
                max={59}
                type="number"
                value={values.minutes}
                onChange={handleChange("minutes")}
                onBlur={handleBlur("minutes")}
              />
              <small className="field-error">{errors.minutes}</small>
            </Form.Field>
            <Form.Field error={"active" in errors} width="5">
              <Form.Checkbox
                onChange={(e, data) => setFieldValue("active", data.checked)}
                label="Activer le trajet."
                checked={values.active}
              />
              <small className="field-error">{errors.active}</small>
            </Form.Field>
          </Form.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default RouteFormModal;
