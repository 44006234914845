import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import * as routesService from "../../../services/routesService";
import dayjs from "dayjs";
import * as yup from "yup";
import * as scheduleService from "../../../services/scheduleService";
import { toast } from "react-toastify";
import TravelsParamsForm from "../../../components/Forms/TravelsParamsForm";
import { getHourFromDate } from "../../../utils/commonsHelper";

const validationSchema = yup.object().shape({
  idroutes: yup.number().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  daysOfWeek: yup.array().required(),
  startHour: yup.string().required(),
  price: yup.number().required(),
});

function ConfigurationPane() {
  const [routes, setRoutes] = useState([]);
  const { paramsId } = useParams();

  const formik = useFormik({
    initialValues: {
      idroutes: "",
      startDate: "",
      endDate: "",
      startHour: "",
      price: 0,
      daysOfWeek: [],
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      scheduleService
        .update(paramsId, values)
        .then(
          () => {
            toast(`Programmation Modifiée avec succès!`, {
              type: "success",
              theme: "colored",
            });
          },
          (reason) => {
            toast(`${reason?.response?.data}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .catch(() => {
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { values, setSubmitting, setFieldValue } = formik;

  const fetchRoutes = async () => {
    try {
      const response = await routesService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        setRoutes(response.data);
      }
    } catch (error) {}
  };

  const fetchTravelParams = () => {
    scheduleService
      .findOne(paramsId)
      .then(
        (response) => {
          setFieldValue("idroutes", response?.data.idroutes, false);
          setFieldValue("price", response?.data.price, false);
          setFieldValue(
            "startDate",
            dayjs(response?.data.startDate).format("YYYY-MM-DD"),
            false
          );
          setFieldValue(
            "endDate",
            dayjs(response?.data.endDate).format("YYYY-MM-DD"),
            false
          );
          setFieldValue(
            "startHour",
            getHourFromDate(response?.data.startHour),
            false
          );
          setFieldValue("daysOfWeek", response?.data.repeatDays, false);
        },
        (reason) => {
          toast(`${reason?.response?.data}`, {
            type: "error",
            theme: "colored",
          });
        }
      )
      .catch(() => {})
      .finally(() => {});
  };

  useEffect(() => {
    if (paramsId) {
      fetchTravelParams();
    }
    return () => {};
  }, [paramsId]);

  useEffect(() => {
    fetchRoutes();

    return () => {};
  }, []);

  const handleRecursiveDaysChange = (e, data) => {
    if (data.checked === true) {
      const newSelection = [...values.daysOfWeek];
      newSelection.push(Number(data.value));
      setFieldValue("daysOfWeek", newSelection);
    } else {
      const newSelection = values.daysOfWeek.filter(
        (d) => Number(d) !== Number(data.value)
      );
      setFieldValue("daysOfWeek", newSelection);
    }
  };
  return (
    <TravelsParamsForm
      formik={formik}
      routes={routes}
      handleRecursiveDaysChange={handleRecursiveDaysChange}
    />
  );
}

export default ConfigurationPane;
