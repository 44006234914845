import { useState, useEffect } from "react";
import {
  Button,
  Grid,
  GridRow,
  Header,
  Icon,
  Form,
  Segment,
  Message,
  Breadcrumb,
  Divider,
} from "semantic-ui-react";
import Page from "../../components/Page";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import * as routesService from "../../services/routesService";
import dayjs from "dayjs";
import * as yup from "yup";
import * as scheduleService from "../../services/scheduleService";
import { toast } from "react-toastify";
import TravelsParamsForm from "../../components/Forms/TravelsParamsForm";
import { getHourFromDate } from "../../utils/commonsHelper";

const validationSchema = yup.object().shape({
  idroutes: yup.number().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  daysOfWeek: yup.array().required(),
  startHour: yup.string().required(),
  price: yup.number().required(),
});
function EditSchedulePage() {
  const [routes, setRoutes] = useState([]);
  const navigate = useNavigate();
  const { paramsId } = useParams();

  const formik = useFormik({
    initialValues: {
      idroutes: "",
      startDate: "",
      endDate: "",
      startHour: "",
      price: 0,
      daysOfWeek: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      scheduleService
        .update(paramsId, values)
        .then(
          (response) => {
            toast(`Programmation Modifiée avec succès!`, {
              type: "success",
              theme: "colored",
            });
            navigate("/agency/schedule");
          },
          (reason) => {
            toast(`${reason?.response?.data}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .catch((reason) => {
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { values, setSubmitting, setFieldValue } = formik;

  const fetchRoutes = async () => {
    try {
      const response = await routesService.fetchAll({ limit: -1 });
      if (response.status === 200) {
        setRoutes(response.data.data);
      }
    } catch (error) { }
  };

  const fetchTravelParams = () => {
    scheduleService
      .findOne(paramsId)
      .then(
        (response) => {
          setFieldValue("idroutes", Number(response?.data.idroutes, true));
          setFieldValue("price", response?.data.price, true);
          setFieldValue(
            "startDate",
            dayjs(response?.data.startDate).format("YYYY-MM-DD")
          );
          setFieldValue(
            "endDate",
            dayjs(response?.data.endDate).format("YYYY-MM-DD")
          );
          setFieldValue("startHour", getHourFromDate(response?.data.startHour));
          setFieldValue("daysOfWeek", response?.data.repeatDays, true);
        },
        (reason) => {
          toast(`${reason?.response?.data}`, {
            type: "error",
            theme: "colored",
          });
        }
      )
      .catch((reason) => { })
      .finally(() => { });
  };

  useEffect(() => {
    if (paramsId !== undefined) {
      fetchTravelParams();
    }
    return () => { };
  }, [paramsId]);

  useEffect(() => {
    fetchRoutes();

    return () => { };
  }, []);

  const handleRecursiveDaysChange = (e, data) => {
    if (data.checked === true) {
      const newSelection = [...values.daysOfWeek];
      newSelection.push(Number(data.value));
      setFieldValue("daysOfWeek", newSelection);
    } else {
      const newSelection = values.daysOfWeek.filter(
        (d) => Number(d) !== Number(data.value)
      );
      setFieldValue("daysOfWeek", newSelection);
    }
  };
  return (
    <Page title="EditSchedulePage">
      <Breadcrumb>
        <Breadcrumb.Section href="/agency">Accueil</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section href="/agency/schedule">Programmation</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Modifier</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>{paramsId}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <GridRow className="EditSchedulePage">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="arrow left" />}
              onClick={() => navigate(-1)}
            />
          </Grid.Column>
        </Grid>
        <TravelsParamsForm
          formik={formik}
          routes={routes}
          handleRecursiveDaysChange={handleRecursiveDaysChange}
        />
      </GridRow>
    </Page>
  );
}

export default EditSchedulePage;
