import { useState, useEffect } from "react";
import { Breadcrumb, Button, Divider, Grid, GridRow, Header, Icon } from "semantic-ui-react";
import Page from "../../components/Page";
import { useNavigate } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import * as routesService from "../../services/routesService";
import * as yup from "yup";
import * as scheduleService from "../../services/scheduleService";
import { toast } from "react-toastify";
import TravelsParamsForm from "../../components/Forms/TravelsParamsForm";

const validationSchema = yup.object().shape({
  idroutes: yup.number().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  daysOfWeek: yup.array().required(),
  startHour: yup.string().required(),
  price: yup.number().required(),
});

function AddSchedulePage() {
  const [routes, setRoutes] = useState([]);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      idroutes: "",
      startDate: "",
      endDate: "",
      startHour: "",
      price: 0,
      daysOfWeek: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      scheduleService
        .create(values)
        .then(
          (response) => {
            toast(`Programmation enregistrée avec succès!`, {
              type: "success",
              theme: "colored",
            });
            navigate("/agency/schedule");
          },
          (reason) => {
            toast(`${reason?.response?.data}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .catch((reason) => {
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setSubmitting,
    setFieldValue,
  } = formik;

  const fetchRoutes = async () => {
    try {
      const response = await routesService.fetchAll({ limit: -1 });
      if (response.status === 200) {
        setRoutes(response.data.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchRoutes();

    return () => { };
  }, []);

  const handleRecursiveDaysChange = (e, data) => {
    if (data.checked === true) {
      const newSelection = [...values.daysOfWeek];
      newSelection.push(Number(data.value));
      setFieldValue("daysOfWeek", newSelection);
    } else {
      const newSelection = values.daysOfWeek.filter(
        (d) => Number(d) !== Number(data.value)
      );
      setFieldValue("daysOfWeek", newSelection);
    }
  };

  return (
    <Page title="AddSchedulePage">
      <Breadcrumb>
        <Breadcrumb.Section href="/agency">Accueil</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section href="/agency/schedule">Programmation</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Ajouter</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <GridRow className="AddSchedulePage">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >

          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="arrow left" />}
              onClick={() => navigate(-1)}
            />
          </Grid.Column>
        </Grid>
        <TravelsParamsForm
          formik={formik}
          routes={routes}
          handleRecursiveDaysChange={handleRecursiveDaysChange}
        />
      </GridRow>
    </Page>
  );
}

export default AddSchedulePage;
