import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/auth";
import {
  getUserFromStorage,
  removeUserFromStorage,
} from "../services/authService";

function ProtectedRoute({ exept = [], children }) {
  const authStore = useRecoilValue(authState);

  const isAuthenticated = !!authStore?.token || !!getUserFromStorage();

  if (
    !isAuthenticated ||
    !(
      exept?.includes(authStore?.user?.user_group) ||
      exept?.includes(getUserFromStorage()?.user?.user_group)
    )
  ) {
    removeUserFromStorage();
    return <Navigate to="/connexion" replace />;
  }

  return <>{children}</>;
}

export default ProtectedRoute;
